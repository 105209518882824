import React from "react";
import "./ContactPage.css";

function ContactPage() {
  return (
    <>
      <div className="contact-page__content">
        <div className="contact-page__line">
          E-mail: <a href="mailto:hkpdgoricasovici@gmail.com">hkpdgoricasovici@gmail.com</a>
        </div>
        <div className="contact-page__line">
          Mob.: <a href="tel:+387 63 999 580">+387 63 999 580</a>
        </div>
      </div>
    </>
  );
}

export default ContactPage;