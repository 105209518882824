let mapData = {
"1001":{"field":"A", "location":"1", "inscription":"Boris Galić - Boro"},
"1002":{"field":"A", "location":"2", "inscription":"Bez oznake"},
"1003":{"field":"A", "location":"3", "inscription":"Obitelj Sielemann"},
"1004":{"field":"A", "location":"4", "inscription":"Bez oznake"},
"1005":{"field":"A", "location":"5", "inscription":"Bez oznake"},
"1006":{"field":"A", "location":"6", "inscription":"Obiteljska grobnica Jerko Paradžik"},
"1007":{"field":"A", "location":"7", "inscription":"Obitelj Mate Galića"},
"1008":{"field":"A", "location":"8", "inscription":"Obitelj Jakov Juroš"},
"1009":{"field":"A", "location":"9", "inscription":"Obiteljska grobnica Jerke Bušić"},
"1010":{"field":"A", "location":"10", "inscription":"Obitelj Mirka Galića - Pegotića"},
"1011":{"field":"A", "location":"11", "inscription":"Obitelj Bušić"},
"1012":{"field":"A", "location":"12", "inscription":"Obitelj Bušić"},
"1013":{"field":"A", "location":"13", "inscription":"Galić \"Tošić\""},
"1014":{"field":"A", "location":"14", "inscription":"Anica Paradžik"},
"1015":{"field":"A", "location":"15", "inscription":"Obiteljska grobnica Vidović Mile"},
"1016":{"field":"A", "location":"16", "inscription":"Obitelj Bušić"},
"1017":{"field":"A", "location":"17", "inscription":"Obiteljska grobnica Veselka Galića - Ćokula"},
"1018":{"field":"A", "location":"18", "inscription":"Obitelj Ivana Galića \"Bariše\""},
"1019":{"field":"A", "location":"19", "inscription":"Bez oznake"},
"1020":{"field":"A", "location":"20", "inscription":"Obitelj Ante Galića pok. Vida"},
"1021":{"field":"A", "location":"21", "inscription":"Obiteljska grobnica Svetan Galić"},
"1022":{"field":"A", "location":"22", "inscription":"Iva Galić"},
"1023":{"field":"A", "location":"23", "inscription":"Petar Bušić - Peija"},
"1024":{"field":"A", "location":"24", "inscription":"Obitelj Paške Galić"},
"1025":{"field":"A", "location":"25", "inscription":"Obitelj Sosa"},
"1026":{"field":"A", "location":"26", "inscription":"Obiteljska grobnica Galić Ante pok. Grge"},
"1027":{"field":"A", "location":"27", "inscription":"Obitelj Ante Galića Mije"},
"1028":{"field":"A", "location":"28", "inscription":"Obitelj Dane Galića"},
"1029":{"field":"A", "location":"29", "inscription":"Obitelj Bušić - Nikšić"},
"1030":{"field":"A", "location":"30", "inscription":"Bez oznake"},
"1031":{"field":"A", "location":"31", "inscription":"Bez oznake"},
"1032":{"field":"A", "location":"32", "inscription":"Obitelj Galić"},
"1033":{"field":"A", "location":"33", "inscription":"Ivan i Iva Galić"},
"1034":{"field":"A", "location":"34", "inscription":"Obitelj Ivana Galića Ike"},
"1035":{"field":"A", "location":"35", "inscription":"Obiteljska grobnica Mire Bušić - Ćirana"},
"1036":{"field":"A", "location":"36", "inscription":"Bez oznake"},
"1037":{"field":"A", "location":"37", "inscription":"Obitelj Kamila Bušića"},
"1038":{"field":"A", "location":"38", "inscription":"Obitelj Velimira Bušića"},
"1039":{"field":"A", "location":"39", "inscription":"Fabjan Matija"},
"1040":{"field":"A", "location":"40", "inscription":"Obitelj Bušić - Gigalović"},
"1041":{"field":"A", "location":"41", "inscription":"Obiteljska grobnica Petra Markić"},
"1042":{"field":"A", "location":"42", "inscription":"Obiteljska grobnica Mije i Stipana Bušić \"Gigalović\""},
"1043":{"field":"A", "location":"43", "inscription":"Bušić Paško"},
"1044":{"field":"A", "location":"44", "inscription":"Obiteljska grobnica Mate Polunić"},
"1045":{"field":"A", "location":"45", "inscription":"Bez oznake"},
"1046":{"field":"A", "location":"46", "inscription":"Obitelj Mate Bušića"},
"1047":{"field":"A", "location":"47", "inscription":"Obitelj Ferde Paradžika"},
"1048":{"field":"A", "location":"48", "inscription":"Obitelj Petra Paradžika"},
"1049":{"field":"A", "location":"49", "inscription":"Obitelj Ante Paradžika"},
"1050":{"field":"A", "location":"50", "inscription":"Bošnjak"},
"1051":{"field":"A", "location":"51", "inscription":"Obitelj Bošnjak"},
"1052":{"field":"A", "location":"52", "inscription":"Bez oznake"},
"1053":{"field":"A", "location":"53", "inscription":"Bez oznake"},
"1054":{"field":"A", "location":"54", "inscription":"Bošnjak"},
"1055":{"field":"A", "location":"55", "inscription":"Obitelj Plejić"},
"1056":{"field":"A", "location":"56", "inscription":"Obiteljska grobnica Ilije i Stjepana (Šćepe) Paradžik"},
"1057":{"field":"A", "location":"57", "inscription":"Obiteljska grobnica Ante Brke Galić"},
"1058":{"field":"A", "location":"58", "inscription":"Obiteljska grobnica Bušić"},
"1059":{"field":"A", "location":"59", "inscription":"Bez oznake"},
"1060":{"field":"A", "location":"60", "inscription":"Obitelj Ljube Paradžika"},
"1061":{"field":"A", "location":"61", "inscription":"Obitelj Petra Bušića - Peše"},
"1062":{"field":"A", "location":"62", "inscription":"Obitelj Ante Sosa - Aće"},
"1063":{"field":"A", "location":"63", "inscription":"Obitelj Viktora Galića - Draganovića"},
"1064":{"field":"A", "location":"64", "inscription":"Bez oznake"},
"1065":{"field":"A", "location":"65", "inscription":"Ana Galić"},
"1066":{"field":"A", "location":"66", "inscription":"Obitelj Galić \"Vicić\""},
"1067":{"field":"A", "location":"67", "inscription":"Obitelj Frane Sose - Ćićić"},
"1068":{"field":"A", "location":"68", "inscription":"Bez oznake"},
"1069":{"field":"A", "location":"69", "inscription":"Sosa"},
"1070":{"field":"A", "location":"70", "inscription":"Obiteljska grobnica Jasak"},
"1071":{"field":"A", "location":"71", "inscription":"Obitelj Bušić"},
"1072":{"field":"A", "location":"72", "inscription":"Obiteljska grobnica Bušić Mate"},
"1073":{"field":"A", "location":"73", "inscription":"Obiteljska grobnica Bušić"},
"1074":{"field":"A", "location":"74", "inscription":"Bez oznake"},
"1075":{"field":"A", "location":"75", "inscription":"Obitelj Sosa"},
"1076":{"field":"A", "location":"76", "inscription":"Bez oznake"},
"1077":{"field":"A", "location":"77", "inscription":"Bez oznake"},
"1078":{"field":"A", "location":"78", "inscription":"Bez oznake"},
"1079":{"field":"A", "location":"79", "inscription":"Obitelj Mate Galića \"Mačeka\""},
"1080":{"field":"A", "location":"80", "inscription":"Obitelj Galić"},
"1081":{"field":"A", "location":"81", "inscription":"Darko | Mate | Jerko Sosa"},
"1082":{"field":"A", "location":"82", "inscription":"Obitelj Ante Galića \"Talić\""},
"1083":{"field":"A", "location":"83", "inscription":"Jozo Galić"},
"1084":{"field":"A", "location":"84", "inscription":"Obitelj Ante Galića"},
"1085":{"field":"A", "location":"85", "inscription":"Obitelj Vinka Galića - Mašić"},
"1086":{"field":"A", "location":"86", "inscription":"Obiteljska grobnica Galić"},
"1087":{"field":"A", "location":"87", "inscription":"Obiteljska grobnica Galić"},
"1088":{"field":"A", "location":"88", "inscription":"Obitelj Sosa"},
"1089":{"field":"A", "location":"89", "inscription":"Obitelj Ivan Galić - Ikan"},
"1090":{"field":"A", "location":"90", "inscription":"Obitelj Sočo Marijan"},
"1091":{"field":"A", "location":"91", "inscription":"Branko Sosa"},
"1092":{"field":"A", "location":"92", "inscription":"Obitelj Nikole Bušića - Buše"},
"1093":{"field":"A", "location":"93", "inscription":"Obitelj Nogalo"},
"1094":{"field":"A", "location":"94", "inscription":"Jasak"},
"1095":{"field":"A", "location":"95", "inscription":"Mirko i Ljubica Bušić"},
"1096":{"field":"A", "location":"96", "inscription":"Mila Bušić"},
"1097":{"field":"A", "location":"97", "inscription":"Obiteljska grobnica Josipa Galić"},
"1098":{"field":"A", "location":"98", "inscription":"Obitelj Bušić"},
"1099":{"field":"A", "location":"99", "inscription":"Obitelj Ante Bušića"},
"1100":{"field":"A", "location":"100", "inscription":"Stjepan Hočevar"},
"1101":{"field":"A", "location":"101", "inscription":"Obitelj Galić"},
"1102":{"field":"A", "location":"102", "inscription":"Obitelj Ivana Bušića - Greje"},
"1103":{"field":"A", "location":"103", "inscription":"Obiteljska grobnica Ivana i Križe Bušić"},
"1104":{"field":"A", "location":"104", "inscription":"Obitelj Žarke Bušića - Žare"},
"2001":{"field":"B", "location":"1", "inscription":"Obiteljska grobnica Ljube Bušića"},
"2002":{"field":"B", "location":"2", "inscription":"Obiteljska grobnica diže Plejić Ante"},
"2003":{"field":"B", "location":"3", "inscription":"Djeca obitelji Sosa"},
"2004":{"field":"B", "location":"4", "inscription":"Mila Grubišić"},
"2005":{"field":"B", "location":"5", "inscription":"Obitelj Stjepana Bušića - Ćipića"},
"2006":{"field":"B", "location":"6", "inscription":"Obiteljska grobnica Joze - Jozine Polunić"},
"2007":{"field":"B", "location":"7", "inscription":"Obitelj Marinko Polunić"},
"2008":{"field":"B", "location":"8", "inscription":"Obitelj Stjepana Bušića - Ćipe"},
"2009":{"field":"B", "location":"9", "inscription":"Obitelj Mirke i Ante Bušića"},
"2010":{"field":"B", "location":"10", "inscription":"Bez oznake"},
"2011":{"field":"B", "location":"11", "inscription":"Obitelj Bušić - Madžarević"},
"2012":{"field":"B", "location":"12", "inscription":"Obiteljska grobnica Mate Markić - Toljak"},
"2013":{"field":"B", "location":"13", "inscription":"Markić Mate"},
"2014":{"field":"B", "location":"14", "inscription":"Obitelj Bušić"},
"2015":{"field":"B", "location":"15", "inscription":"Obitelj Petra Bušić - Mašić"},
"2016":{"field":"B", "location":"16", "inscription":"Bez oznake"},
"2017":{"field":"B", "location":"17", "inscription":"Obitelj Stipan Kovač - Ćipe"},
"2018":{"field":"B", "location":"18", "inscription":"Obitelj Paradžik"},
"2019":{"field":"B", "location":"19", "inscription":"Obiteljska grobnica Joze Bušić - Josac"},
"2020":{"field":"B", "location":"20", "inscription":"Obitelj Bušić Danke"},
"2021":{"field":"B", "location":"21", "inscription":"Spomenik podiže Grubišić Jozi..."},
"2022":{"field":"B", "location":"22", "inscription":"Obitelj Jerke Bušić - Jerkače"},
"2023":{"field":"B", "location":"23", "inscription":"Obitelj Ante Sosa \"Strikan\""},
"2024":{"field":"B", "location":"24", "inscription":"Obitelj Kapural - Jagića"},
"2025":{"field":"B", "location":"25", "inscription":"Obitelj Stjepana Kapular"},
"2026":{"field":"B", "location":"26", "inscription":"Obiteljska grobnica Stjepana Kapural"},
"2027":{"field":"B", "location":"27", "inscription":"Bušić"},
"2028":{"field":"B", "location":"28", "inscription":"Obitelj Kapural"},
"2029":{"field":"B", "location":"29", "inscription":"Bez oznake"},
"2030":{"field":"B", "location":"30", "inscription":"Bez oznake"},
"2031":{"field":"B", "location":"31", "inscription":"Bez oznake"},
"2032":{"field":"B", "location":"32", "inscription":"Bez oznake"},
"2033":{"field":"B", "location":"33", "inscription":"Bez oznake"},
"2034":{"field":"B", "location":"34", "inscription":"Bez oznake"},
"2035":{"field":"B", "location":"35", "inscription":"Obitelj Bošnjak"},
"2036":{"field":"B", "location":"36", "inscription":"Obitelj Bušić"},
"2037":{"field":"B", "location":"37", "inscription":"Obitelj Dominika Paradžika"},
"2038":{"field":"B", "location":"38", "inscription":"Obitelj Paradžik"},
"2039":{"field":"B", "location":"39", "inscription":"Obitelj Mijata Galića"},
"2040":{"field":"B", "location":"40", "inscription":"Obiteljska grobnica Polunić  Manjić"},
"2041":{"field":"B", "location":"41", "inscription":"Obitelj Mate Bušića - Mađe"},
"2042":{"field":"B", "location":"42", "inscription":"Obitelj Stjepana Bošnjak - Buce"},
"2043":{"field":"B", "location":"43", "inscription":"Obitelj Matka Bušića"},
"2044":{"field":"B", "location":"44", "inscription":"Obitelj Ante Grubišića - Živkovića"},
"2045":{"field":"B", "location":"45", "inscription":"Obitelj Ante Bušić - Barišić"},
"2046":{"field":"B", "location":"46", "inscription":"Obiteljska grobnica Stjepana Galića"},
"2047":{"field":"B", "location":"47", "inscription":"Obiteljska grobnica Galić"},
"2048":{"field":"B", "location":"48", "inscription":"Obiteljska grobnica Galić"},
"2049":{"field":"B", "location":"49", "inscription":"Bez oznake"},
"2050":{"field":"B", "location":"50", "inscription":"Bez oznake"},
"2051":{"field":"B", "location":"51", "inscription":"Bez oznake"},
"2052":{"field":"B", "location":"52", "inscription":"Bez oznake"},
"2053":{"field":"B", "location":"53", "inscription":"Bez oznake"},
"2054":{"field":"B", "location":"54", "inscription":"Bez oznake"},
"2055":{"field":"B", "location":"55", "inscription":"Obitelj Frane Galića - Bićaljević"},
"2056":{"field":"B", "location":"56", "inscription":"Obitelj Ante Bušić - Pijukanović"},
"2057":{"field":"B", "location":"57", "inscription":"Obiteljska grobnica Stjepana Bušića - Ćike"},
"2058":{"field":"B", "location":"58", "inscription":"Ovdje počivaju tijela...Bušić"},
"2059":{"field":"B", "location":"59", "inscription":"Obiteljska grobnica Kapural Blaž"},
"2060":{"field":"B", "location":"60", "inscription":"Bušić Stjepan | Adam | Franjka | Iva"},
"2061":{"field":"B", "location":"61", "inscription":"Bez oznake"},
"2062":{"field":"B", "location":"62", "inscription":"Obiteljska grobnica Jozo Bušić"},
"2063":{"field":"B", "location":"63", "inscription":"Bez oznake"},
"2064":{"field":"B", "location":"64", "inscription":"Bez oznake"},
"2065":{"field":"B", "location":"65", "inscription":"Bez oznake"},
"2066":{"field":"B", "location":"66", "inscription":"Bez oznake"},
"2067":{"field":"B", "location":"67", "inscription":"Polunić"},
"2068":{"field":"B", "location":"68", "inscription":"Bez oznake"},
"2069":{"field":"B", "location":"69", "inscription":"Obitelj Bušić"},
"2070":{"field":"B", "location":"70", "inscription":"Obitelj Paradžik"},
"2071":{"field":"B", "location":"71", "inscription":"Obitelj Darinko (Daran) Galić - Šolić"},
"2072":{"field":"B", "location":"72", "inscription":"Bez oznake"},
"2073":{"field":"B", "location":"73", "inscription":"Obitelj Bušić"},
"2074":{"field":"B", "location":"74", "inscription":"Bez oznake"},
"2075":{"field":"B", "location":"75", "inscription":"Obitelj Paradžik"},
"2076":{"field":"B", "location":"76", "inscription":"Galić"},
"2077":{"field":"B", "location":"77", "inscription":"Mate i Jaka Paradžik"},
"2078":{"field":"B", "location":"78", "inscription":"Obiteljska grobnica Cvitana, Matije i Zdravke Paradžik"},
"2079":{"field":"B", "location":"79", "inscription":"Bušić"},
"2080":{"field":"B", "location":"80", "inscription":"Bušić Šarić"},
"2081":{"field":"B", "location":"81", "inscription":"Obitelj Ante Bušić - Perišić"},
"2082":{"field":"B", "location":"82", "inscription":"Bez oznake"},
"2083":{"field":"B", "location":"83", "inscription":"Bez oznake"},
"2084":{"field":"B", "location":"84", "inscription":"Bez oznake"},
"2085":{"field":"B", "location":"85", "inscription":"Bez oznake"},
"2086":{"field":"B", "location":"86", "inscription":"Bez oznake"},
"2087":{"field":"B", "location":"87", "inscription":"Anta i Jura Jasak"},
"2088":{"field":"B", "location":"88", "inscription":"Anka Jasak"},
"2089":{"field":"B", "location":"89", "inscription":"Obitelj Jasak"},
"2090":{"field":"B", "location":"90", "inscription":"Jasak"},
"2091":{"field":"B", "location":"91", "inscription":"Bušić Danica i Branko"},
"2092":{"field":"B", "location":"92", "inscription":"Obiteljska grobnica Petra Kordića"},
"2093":{"field":"B", "location":"93", "inscription":"Bez oznake"},
"2094":{"field":"B", "location":"94", "inscription":"Bez oznake"},
"2095":{"field":"B", "location":"95", "inscription":"Obitelj Ante Bošnjaka \"Viduka\""},
"2096":{"field":"B", "location":"96", "inscription":"Obitelj Stipan Sosa - Marangun"},
"2097":{"field":"B", "location":"97", "inscription":"Vladislav Bušić"},
"2098":{"field":"B", "location":"98", "inscription":"Obiteljska grobnica Danke Galića"},
"2099":{"field":"B", "location":"99", "inscription":"Obiteljska grobnica Marko Bušić - Maran"},
"2100":{"field":"B", "location":"100", "inscription":"Obitelj Milke Galić Tošić"},
"2101":{"field":"B", "location":"101", "inscription":"Obitelj Ivana Galića"},
"2102":{"field":"B", "location":"102", "inscription":"Obitelj Drage Paradžika"},
"2103":{"field":"B", "location":"103", "inscription":"Bez oznake"},
"2104":{"field":"B", "location":"104", "inscription":"Obitelj Bušić Petra - Periša"},
"2105":{"field":"B", "location":"105", "inscription":"Obiteljska grobnica Petar Bušić - Vegarić"},
"2106":{"field":"B", "location":"106", "inscription":"Bez oznake"},
"2107":{"field":"B", "location":"107", "inscription":"Boban Jerko | Danica"},
"2108":{"field":"B", "location":"108", "inscription":"Obiteljska grobnica Paradžik"},
"2109":{"field":"B", "location":"109", "inscription":"Paradžik Matija | Jakov | Ruža"},
"2110":{"field":"B", "location":"110", "inscription":"Paradžik Jozo | Matija | Ivan | Dinka"},
"2111":{"field":"B", "location":"111", "inscription":"Obiteljska grobnica Jure Paradžika"},
"2112":{"field":"B", "location":"112", "inscription":"Bez oznake"},
"2113":{"field":"B", "location":"113", "inscription":"Bez oznake"},
"2114":{"field":"B", "location":"114", "inscription":"Paško Galić | Luca Vlašić"},
"2115":{"field":"B", "location":"115", "inscription":"Obitelj Jurić"},
"2116":{"field":"B", "location":"116", "inscription":"Obiteljska grobnica Bušić"},
"2117":{"field":"B", "location":"117", "inscription":"Obiteljska grobnica Nikole Bušića Kendića"},
"2118":{"field":"B", "location":"118", "inscription":"Obitelj Petra Bušića - Panjca"},
"2119":{"field":"B", "location":"119", "inscription":"Obitelj Bušić"},
"2120":{"field":"B", "location":"120", "inscription":"Bez oznake"},
"2121":{"field":"B", "location":"121", "inscription":"Bez oznake"},
"2122":{"field":"B", "location":"122", "inscription":"Bez oznake"},
"2123":{"field":"B", "location":"123", "inscription":"Bez oznake"},
"2124":{"field":"B", "location":"124", "inscription":"Obiteljska grobnica Polunić"},
"2125":{"field":"B", "location":"125", "inscription":"Obitelj Darke Bušića"},
"2126":{"field":"B", "location":"126", "inscription":"Manda Brnjić"},
"2127":{"field":"B", "location":"127", "inscription":"Obiteljska grobnica Čutura Nikole"},
"2128":{"field":"B", "location":"128", "inscription":"Obitelj Galić"},
"2129":{"field":"B", "location":"129", "inscription":"Srećko Bošnjak"},
"2130":{"field":"B", "location":"130", "inscription":"Obitelj Joze Jasaka"},
"2131":{"field":"B", "location":"131", "inscription":"Obitelj Petra Bošnjaka"},
"2132":{"field":"B", "location":"132", "inscription":"Obiteljska grobnica Ferde Galića"},
"2133":{"field":"B", "location":"133", "inscription":"Matija Paradžik"},
"2134":{"field":"B", "location":"134", "inscription":"Obitelj Kapural"},
"2135":{"field":"B", "location":"135", "inscription":"Obiteljska grobnica Galić"},
"2136":{"field":"B", "location":"136", "inscription":"Obitelj Zlatan Galić"},
"2137":{"field":"B", "location":"137", "inscription":"Obitelj Mijo Galić - Jakišić"},
"2138":{"field":"B", "location":"138", "inscription":"Obitelj Veljke Galića - Svetanova"},
"2139":{"field":"B", "location":"139", "inscription":"Obiteljska grobnica Darka Galića"},
"2140":{"field":"B", "location":"140", "inscription":"Galić Mate - Matas"},
"2141":{"field":"B", "location":"141", "inscription":"Bez oznake"},
"2142":{"field":"B", "location":"142", "inscription":"Obitelj Drage Galića - Jusića"},
"2143":{"field":"B", "location":"143", "inscription":"Obitelj Martina Galić"},
"2144":{"field":"B", "location":"144", "inscription":"Obitelj Frane Bušića"},
"2145":{"field":"B", "location":"145", "inscription":"Dieter Taubeneck"},
"2146":{"field":"B", "location":"146", "inscription":"Obitelj Ivana Galića - Iće"},
"2147":{"field":"B", "location":"147", "inscription":"Bez oznake"},
"2148":{"field":"B", "location":"148", "inscription":"Stjepan Galić - Šolić"},
"2149":{"field":"B", "location":"149", "inscription":"Bez oznake"},
"2150":{"field":"B", "location":"150", "inscription":"Obitelj Kreše Bušića - Lovrića"},
"2151":{"field":"B", "location":"151", "inscription":"Obiteljska grobnica Petra Galića"},
"2152":{"field":"B", "location":"152", "inscription":"Obiteljska grobnica Sosa"},
"2153":{"field":"B", "location":"153", "inscription":"Obiteljska grobnica Ante Galić - Antota"},
"2154":{"field":"B", "location":"154", "inscription":"Obitelj Galić"},
"2155":{"field":"B", "location":"155", "inscription":"Obitelj Sosa"},
"2156":{"field":"B", "location":"156", "inscription":"Obitelj Galić"},
"2157":{"field":"B", "location":"157", "inscription":"Obitelj Matuzela Galića"},
"2158":{"field":"B", "location":"158", "inscription":"Bez oznake"},
"2159":{"field":"B", "location":"159", "inscription":"Bez oznake"},
"2160":{"field":"B", "location":"160", "inscription":"Obitelj Cvitana Galića"},
"2161":{"field":"B", "location":"161", "inscription":"Obitelj Galić"},
"2162":{"field":"B", "location":"162", "inscription":"Obitelj Slave Galića"},
"2163":{"field":"B", "location":"163", "inscription":"Bez oznake"},
"2164":{"field":"B", "location":"164", "inscription":"Bez oznake"},
"2165":{"field":"B", "location":"165", "inscription":"Galić Toma - Toše"},
"2166":{"field":"B", "location":"166", "inscription":"Bez oznake"},
"2167":{"field":"B", "location":"167", "inscription":"Bez oznake"},
"2168":{"field":"B", "location":"168", "inscription":"Obitelj Stipana Bušića - Lovrića"},
"2169":{"field":"B", "location":"169", "inscription":"Bez oznake"},
"2170":{"field":"B", "location":"170", "inscription":"Bez oznake"},
"2171":{"field":"B", "location":"171", "inscription":"Obitelj Stipan Bušić - Radeškić"},
"2172":{"field":"B", "location":"172", "inscription":"Bez oznake"},
"2173":{"field":"B", "location":"173", "inscription":"Obitelj Ante Bobana - Matišića"},
"2174":{"field":"B", "location":"174", "inscription":"Obitelj Ivana Galića pok. Nikole"},
"2175":{"field":"B", "location":"175", "inscription":"Obiteljska grobnica Dragana Galića"},
"2176":{"field":"B", "location":"176", "inscription":"Obitelj Mirke Galića Matina"},
"2177":{"field":"B", "location":"177", "inscription":"Bez oznake"},
"2178":{"field":"B", "location":"178", "inscription":"Obitelj Blage Galića"},
"2179":{"field":"B", "location":"179", "inscription":"Bez oznake"},
"2180":{"field":"B", "location":"180", "inscription":"Obitelj Ante Galić - Ikarić"},
"2181":{"field":"B", "location":"181", "inscription":"Obitelj Vinka Bušića"},
"2182":{"field":"B", "location":"182", "inscription":"Obiteljska grobnica Jure Bušić"},
"2183":{"field":"B", "location":"183", "inscription":"Bez oznake"},
"2184":{"field":"B", "location":"184", "inscription":"Obitelj Marinka i Dade Bušića"},
"2185":{"field":"B", "location":"185", "inscription":"Bez oznake"},
"2186":{"field":"B", "location":"186", "inscription":"Bez oznake"},
"2187":{"field":"B", "location":"187", "inscription":"Bez oznake"},
"2188":{"field":"B", "location":"188", "inscription":"Obitelj Galić"},
"2189":{"field":"B", "location":"189", "inscription":"Obiteljska grobnica Ante Galić (Bičaljević)"},
"2190":{"field":"B", "location":"190", "inscription":"Obiteljska grobnica Matiše Galića"},
"2191":{"field":"B", "location":"191", "inscription":"Ante Galić"},
"2192":{"field":"B", "location":"192", "inscription":"Bušić Matija - Maca"},
"2193":{"field":"B", "location":"193", "inscription":"Galić Petar | Iva - Baguša | Iva - Grubišuša"},
"3001":{"field":"C", "location":"1", "inscription":"Obitelj Stjepana Galića \"Šćepe\""},
"3002":{"field":"C", "location":"2", "inscription":"Obiteljska grobnica Galić Ante i Anice"},
"3003":{"field":"C", "location":"3", "inscription":"Braća Bušići Andrija i Cvitan"},
"3004":{"field":"C", "location":"4", "inscription":"Obitelj Vulić"},
"3005":{"field":"C", "location":"5", "inscription":"Obiteljska grobnica Antasa Galića"},
"3006":{"field":"C", "location":"6", "inscription":"Obitelj Petra i Matije Galić"},
"3007":{"field":"C", "location":"7", "inscription":"Obiteljska grobnica Mate - Mašana - Galića"},
"3008":{"field":"C", "location":"8", "inscription":"Obitelj Galić - Šolić"},
"3009":{"field":"C", "location":"9", "inscription":"Obiteljska grobnica Galić"},
"3010":{"field":"C", "location":"10", "inscription":"Obitelj Ivana Bušića - Nikije"},
"3011":{"field":"C", "location":"11", "inscription":"Obiteljska grobnica Bušić"},
"3012":{"field":"C", "location":"12", "inscription":"Bez oznake"},
"3013":{"field":"C", "location":"13", "inscription":"Obiteljska grobnica Bušić Petra \"Perana\""},
"3014":{"field":"C", "location":"14", "inscription":"Obiteljska grobnica Plejić Mate"},
"3015":{"field":"C", "location":"15", "inscription":"Obiteljska grobnica Ivan Bušić - Ikina"},
"3016":{"field":"C", "location":"16", "inscription":"Obiteljska grobnica Andrije Bušić"},
"3017":{"field":"C", "location":"17", "inscription":"Obitelj Ranimira Bušića"},
"3018":{"field":"C", "location":"18", "inscription":"Jure i Matija Galić"},
"3019":{"field":"C", "location":"19", "inscription":"Obitelj Stjepana Galića"},
"3020":{"field":"C", "location":"20", "inscription":"Obitelj Dominika Galića"},
"3021":{"field":"C", "location":"21", "inscription":"Vinka Čutura"},
"3022":{"field":"C", "location":"22", "inscription":"Obiteljska grobnica Stjepana Misira"},
"3023":{"field":"C", "location":"23", "inscription":"Obiteljska grobnica Misir Ivana"},
"3024":{"field":"C", "location":"24", "inscription":"Obiteljska grobnica Misir Jakova"},
"3025":{"field":"C", "location":"25", "inscription":"Obiteljska grobnica Bogut Pavao"},
"3026":{"field":"C", "location":"26", "inscription":"Obiteljska grobnica Bogut Grge"},
"3027":{"field":"C", "location":"27", "inscription":"Obitelj Stjepana Vokića"},
"3028":{"field":"C", "location":"28", "inscription":"Obiteljska grobnica Ivana Boguta - Ivide"},
"3029":{"field":"C", "location":"29", "inscription":"Blaž Bogut"},
"3030":{"field":"C", "location":"30", "inscription":"Bogut"},
"3031":{"field":"C", "location":"31", "inscription":"Obitelj Soldo"},
"3032":{"field":"C", "location":"32", "inscription":"Obiteljska grobnica Bogut"},
"3033":{"field":"C", "location":"33", "inscription":"Bez oznake"},
"3034":{"field":"C", "location":"34", "inscription":"Obitelj Biško"},
"3035":{"field":"C", "location":"35", "inscription":"Vlašić - Ambrožić"},
"3036":{"field":"C", "location":"36", "inscription":"Jakov i Kata Vlašić"},
"3037":{"field":"C", "location":"37", "inscription":"Obitelj Kapural"},
"3038":{"field":"C", "location":"38", "inscription":"Obiteljska grobnica Zlatka Jelića"},
"3039":{"field":"C", "location":"39", "inscription":"Obitelj Jasak"},
"3040":{"field":"C", "location":"40", "inscription":"Obitelj Nedjeljka Grizelja Džinića"},
"3041":{"field":"C", "location":"41", "inscription":"Bez oznake"},
"3042":{"field":"C", "location":"42", "inscription":"Obiteljska grobnica Ante Soldo"},
"3043":{"field":"C", "location":"43", "inscription":"Obitelj Soldo"},
"3044":{"field":"C", "location":"44", "inscription":"Misir | Boban"},
"3045":{"field":"C", "location":"45", "inscription":"Vlašić Ambrožić"},
"3046":{"field":"C", "location":"46", "inscription":"Bez oznake"},
"3047":{"field":"C", "location":"47", "inscription":"Bez oznake"},
"3048":{"field":"C", "location":"48", "inscription":"Obitelj Jure Vlašić - Ambrožić"},
"3049":{"field":"C", "location":"49", "inscription":"Bez oznake"},
"3050":{"field":"C", "location":"50", "inscription":"Branko Vulić"},
"3051":{"field":"C", "location":"51", "inscription":"Obitelj Misir"},
"3052":{"field":"C", "location":"52", "inscription":"Obitelj Misir - Špičić"},
"3053":{"field":"C", "location":"53", "inscription":"Obitelj Srećka Bazine"},
"3054":{"field":"C", "location":"54", "inscription":"Obiteljska grobnica Milana Bazine"},
"3055":{"field":"C", "location":"55", "inscription":"Obiteljska grobnica Andrija Bazine"},
"3056":{"field":"C", "location":"56", "inscription":"Obiteljska grobnica Blaža Biške"},
"3057":{"field":"C", "location":"57", "inscription":"Obiteljska grobnica Vokić"},
"3058":{"field":"C", "location":"58", "inscription":"Obiteljska grobnica Mate Vokić"},
"3059":{"field":"C", "location":"59", "inscription":"Obitelj Logara"},
"3060":{"field":"C", "location":"60", "inscription":"Dominik Milićević | Mirko Vokić"},
"3061":{"field":"C", "location":"61", "inscription":"Obitelj Ante Vokića"},
"3062":{"field":"C", "location":"62", "inscription":"Bez oznake"},
"3063":{"field":"C", "location":"63", "inscription":"Bez oznake"},
"3064":{"field":"C", "location":"64", "inscription":"Bez oznake"},
"3065":{"field":"C", "location":"65", "inscription":"Bez oznake"},
"3066":{"field":"C", "location":"66", "inscription":"Obiteljska grobnica Blaža Solde"},
"3067":{"field":"C", "location":"67", "inscription":"Bez oznake"},
"3068":{"field":"C", "location":"68", "inscription":"Logara Ivan | Iva"},
"3069":{"field":"C", "location":"69", "inscription":"Bez oznake"},
"3070":{"field":"C", "location":"70", "inscription":"Biško Ivan | Jerko | Miro"},
"3071":{"field":"C", "location":"71", "inscription":"Obitelj Ljubo Ćavar"},
"3072":{"field":"C", "location":"72", "inscription":"Obitelj Ante Ćavara"},
"3073":{"field":"C", "location":"73", "inscription":"Kata Grizelj"},
"3074":{"field":"C", "location":"74", "inscription":"Jerko Grubišić"},
"3075":{"field":"C", "location":"75", "inscription":"Obiteljska grobnica Jozo - Jozara Bazina"},
"3076":{"field":"C", "location":"76", "inscription":"Obiteljska grobnica Ante Bazina"},
"3077":{"field":"C", "location":"77", "inscription":"Mile Bazina"},
"3078":{"field":"C", "location":"78", "inscription":"Križan Bazina"},
"3079":{"field":"C", "location":"79", "inscription":"Obitelj Bogut"},
"3080":{"field":"C", "location":"80", "inscription":"Obitelj Bogut"},
"3081":{"field":"C", "location":"81", "inscription":"Bogut"},
"3082":{"field":"C", "location":"82", "inscription":"Bogut"},
"3083":{"field":"C", "location":"83", "inscription":"Obiteljska grobnica Ivana Vranješa"},
"3084":{"field":"C", "location":"84", "inscription":"Bez oznake"},
"3085":{"field":"C", "location":"85", "inscription":"Bez oznake"},
"3086":{"field":"C", "location":"86", "inscription":"Obitelj Vida Soldo"},
"3087":{"field":"C", "location":"87", "inscription":"Obitelj Soldo"},
"3088":{"field":"C", "location":"88", "inscription":"Obiteljska grobnica Frano Soldo"},
"3089":{"field":"C", "location":"89", "inscription":"Obitelj Vlašić - Ambrožić"},
"3090":{"field":"C", "location":"90", "inscription":"Bez oznake"},
"3091":{"field":"C", "location":"91", "inscription":"Jozo Grizelj"},
"3092":{"field":"C", "location":"92", "inscription":"Mate Bazina"},
"3093":{"field":"C", "location":"93", "inscription":"Obitelj Pejić"},
"3094":{"field":"C", "location":"94", "inscription":"Obitelj Biško"},
"3095":{"field":"C", "location":"95", "inscription":"Obitelj Vinko Biško"},
"3096":{"field":"C", "location":"96", "inscription":"Obiteljska grobnica Miroslava Bazine"},
"3097":{"field":"C", "location":"97", "inscription":"Obiteljska grobnica Jerke Bazine"},
"3098":{"field":"C", "location":"98", "inscription":"Obiteljska grobnica Stjepana Misira p. Petra"},
"3099":{"field":"C", "location":"99", "inscription":"Vokić"},
"3100":{"field":"C", "location":"100", "inscription":"Bez oznake"},
"3101":{"field":"C", "location":"101", "inscription":"Nikola Biško"},
"3102":{"field":"C", "location":"102", "inscription":"Obitelj Hohnjec"},
"3103":{"field":"C", "location":"103", "inscription":"Bez oznake"},
"3104":{"field":"C", "location":"104", "inscription":"Bez oznake"},
"3105":{"field":"C", "location":"105", "inscription":"Grizelj - Marijanović"},
"3106":{"field":"C", "location":"106", "inscription":"Grizelj - Marijanović"},
"3107":{"field":"C", "location":"107", "inscription":"Spomen podižu ocu Ivanu Zoriću"},
"3108":{"field":"C", "location":"108", "inscription":"Obiteljska grobnica Šimić"},
"3109":{"field":"C", "location":"109", "inscription":"Obiteljska grobnica Šimić"},
"3110":{"field":"C", "location":"110", "inscription":"Bez oznake"},
"3111":{"field":"C", "location":"111", "inscription":"Spomen podiže Ivan Bazina"},
"3112":{"field":"C", "location":"112", "inscription":"Obiteljska grobnica Logara Ivan"},
"3113":{"field":"C", "location":"113", "inscription":"Lovre Biško"},
"3114":{"field":"C", "location":"114", "inscription":"Ovjde počiva tijelo Misira Jure"},
"3115":{"field":"C", "location":"115", "inscription":"Bez oznake"},
"3116":{"field":"C", "location":"116", "inscription":"Obitelj Jure Grizelja - Marijanovića"},
"3117":{"field":"C", "location":"117", "inscription":"Obitelj Jure Grizelja - Marijanovića"},
"3118":{"field":"C", "location":"118", "inscription":"Obitelj Logara"},
"3119":{"field":"C", "location":"119", "inscription":"Ovdje počiva Toma Šlišković"},
"3120":{"field":"C", "location":"120", "inscription":"Obiteljska grobnica Bogut Gabro"},
"3121":{"field":"C", "location":"121", "inscription":"Obitelj Zorić"},
"3122":{"field":"C", "location":"122", "inscription":"Bez oznake"},
"3123":{"field":"C", "location":"123", "inscription":"Marko Zorić"},
"3124":{"field":"C", "location":"124", "inscription":"Bez oznake"},
"3125":{"field":"C", "location":"125", "inscription":"Bez oznake"},
"3126":{"field":"C", "location":"126", "inscription":"Ovdje počiva tijelo Stipana Čuljka"},
"3127":{"field":"C", "location":"127", "inscription":"Bez oznake"},
"3128":{"field":"C", "location":"128", "inscription":"Obitelj Vlašić - Ambrožić"},
"3129":{"field":"C", "location":"129", "inscription":"Obiteljska grobnica Ante Vokić"},
"3130":{"field":"C", "location":"130", "inscription":"Obiteljska grobnica Ante Vokić"},
"3131":{"field":"C", "location":"131", "inscription":"Ovdje počiva tijelo Miše Tucak"},
"3132":{"field":"C", "location":"132", "inscription":"Obitelj Stipe Jelić"},
"3133":{"field":"C", "location":"133", "inscription":"Bez oznake"},
"3134":{"field":"C", "location":"134", "inscription":"Bez oznake"},
"3135":{"field":"C", "location":"135", "inscription":"Obitelj Ante Grizelja"},
"3136":{"field":"C", "location":"136", "inscription":"Obitelj Petra - Perke Grubišića"},
"3137":{"field":"C", "location":"137", "inscription":"Obitelj Grizelj Grlanović"},
"3138":{"field":"C", "location":"138", "inscription":"Obitelj Grizelj Grlanović"},
"3139":{"field":"C", "location":"139", "inscription":"Ovdje počivaju Frano i Iva Vlašić"},
"3140":{"field":"C", "location":"140", "inscription":"Ivan Grizelj - Vitelić"},
"3141":{"field":"C", "location":"141", "inscription":"Ivan Grizelj - Vitelić"},
"3142":{"field":"C", "location":"142", "inscription":"Obitelj Ivana Grizelja"},
"3143":{"field":"C", "location":"143", "inscription":"Tihi dom obitelji Slišković"},
"3144":{"field":"C", "location":"144", "inscription":"Tihi dom obitelji Slišković"},
"3145":{"field":"C", "location":"145", "inscription":"Obiteljska grobnica Mate Boguta - Brkovića"},
"3146":{"field":"C", "location":"146", "inscription":"Bez oznake"},
"3147":{"field":"C", "location":"147", "inscription":"Bez oznake"},
"3148":{"field":"C", "location":"148", "inscription":"Obiteljska grobnica Jure Boguta"},
"3149":{"field":"C", "location":"149", "inscription":"Obiteljska grobnica Stjepan Vokić"},
"3150":{"field":"C", "location":"150", "inscription":"Obiteljska grobnica Stjepan Vokić"},
"3151":{"field":"C", "location":"151", "inscription":"Obitelj Marijana Vokić"},
"3152":{"field":"C", "location":"152", "inscription":"Obitelj Mile Vokić"},
"3153":{"field":"C", "location":"153", "inscription":"Ovdje počiva Gabro Grizelj i ž. Anđa"},
"3154":{"field":"C", "location":"154", "inscription":"Obitelj Martić"},
"3155":{"field":"C", "location":"155", "inscription":"Obitelj Martić"},
"3156":{"field":"C", "location":"156", "inscription":"Obitelj Bogut"},
"3157":{"field":"C", "location":"157", "inscription":"Obitelj Soldo"},
"3158":{"field":"C", "location":"158", "inscription":"Bez oznake"},
"3159":{"field":"C", "location":"159", "inscription":"Obitelj Žarko Grizelj"},
"3160":{"field":"C", "location":"160", "inscription":"Obitelj Jerke Grizelja"},
"3161":{"field":"C", "location":"161", "inscription":"Obiteljska grobnica Ante Grizelja Džinića"},
"3162":{"field":"C", "location":"162", "inscription":"Obiteljska grobnica Frano Pejić"},
"3163":{"field":"C", "location":"163", "inscription":"Obitelj Ante Grizelja - Jokanovića"},
"3164":{"field":"C", "location":"164", "inscription":"Bez oznake"},
"3165":{"field":"C", "location":"165", "inscription":"Obiteljska grobnica Ivana Zorića"},
"3166":{"field":"C", "location":"166", "inscription":"Obitelj Petra Zorića"},
"3167":{"field":"C", "location":"167", "inscription":"Obitelj Vlašić Nikola"},
"3168":{"field":"C", "location":"168", "inscription":"Bez oznake"},
"3169":{"field":"C", "location":"169", "inscription":"Bazina"},
"3170":{"field":"C", "location":"170", "inscription":"Bez oznake"},
"3171":{"field":"C", "location":"171", "inscription":"Bez oznake"},
"3172":{"field":"C", "location":"172", "inscription":"Knezović"},
"3173":{"field":"C", "location":"173", "inscription":"Obitelj Milićević"},
"3174":{"field":"C", "location":"174", "inscription":"Obitelj Jerko i Anđa Jelić"},
"3175":{"field":"C", "location":"175", "inscription":"Obitelj Ante Vlašić - Ambrožić"},
"3176":{"field":"C", "location":"176", "inscription":"Martić"},
"3177":{"field":"C", "location":"177", "inscription":"Obitelj Bogut"},
"3178":{"field":"C", "location":"178", "inscription":"Obiteljska grobnica Stipana Grizelj"},
"3179":{"field":"C", "location":"179", "inscription":"Ovdje počiva Paško Vlašić"},
"3180":{"field":"C", "location":"180", "inscription":"U spomen Anuši i njenom rodu"},
"3181":{"field":"C", "location":"181", "inscription":"Obitelj Biško"},
"3182":{"field":"C", "location":"182", "inscription":"Bez oznake"},
"3183":{"field":"C", "location":"183", "inscription":"Obitelj Biško"},
"3184":{"field":"C", "location":"184", "inscription":"Bez oznake"},
"3185":{"field":"C", "location":"185", "inscription":"Obitelj Vulić"},
"3186":{"field":"C", "location":"186", "inscription":"Obitelj Grizelj"},
"3187":{"field":"C", "location":"187", "inscription":"Obitelj Grizelj"},
"3188":{"field":"C", "location":"188", "inscription":"Obiteljska grobnica Mile Vlašić - Rudić"},
"3189":{"field":"C", "location":"189", "inscription":"Obitelj Ivana i Pave Pejić"},
"3190":{"field":"C", "location":"190", "inscription":"Bez oznake"},
"3191":{"field":"C", "location":"191", "inscription":"Obitelj Divković"},
"3192":{"field":"C", "location":"192", "inscription":"Obitelj Željke Benovića \"Ćotijića\""},
"3193":{"field":"C", "location":"193", "inscription":"Knezović Ljuba"},
"3194":{"field":"C", "location":"194", "inscription":"Majci i ujcu Ivan Grizelj Vitelić"},
"3195":{"field":"C", "location":"195", "inscription":"Obitelj Sulić"},
"3196":{"field":"C", "location":"196", "inscription":"Obiteljska grobnica Šimuna Jelića"},
"3197":{"field":"C", "location":"197", "inscription":"Bez oznake"},
"3198":{"field":"C", "location":"198", "inscription":"Obiteljska grobnica Boban"},
"3199":{"field":"C", "location":"199", "inscription":"Bez oznake"},
"3200":{"field":"C", "location":"200", "inscription":"Obitelj Frane Bobana"},
"3201":{"field":"C", "location":"201", "inscription":"Obitelj Ivan Kozina"},
"3202":{"field":"C", "location":"202", "inscription":"Obitelj Luka Kozina"},
"3203":{"field":"C", "location":"203", "inscription":"Janja Milićević"},
"3204":{"field":"C", "location":"204", "inscription":"Bez oznake"},
"3205":{"field":"C", "location":"205", "inscription":"Milićević Anto | Doma | Šimun"},
"3206":{"field":"C", "location":"206", "inscription":"Bez oznake"},
"3207":{"field":"C", "location":"207", "inscription":"Obitelj Ivana Misira \"Ikice\""},
"3208":{"field":"C", "location":"208", "inscription":"Obiteljska grobnica Relja Matija p. Krste"},
"3209":{"field":"C", "location":"209", "inscription":"Obitelj Boban"},
"3210":{"field":"C", "location":"210", "inscription":"Obitelj Ivana Grubišića"},
"3211":{"field":"C", "location":"211", "inscription":"Obiteljska grobnica Ante i Anđe Grubišić"},
"3212":{"field":"C", "location":"212", "inscription":"Bez oznake"},
"3213":{"field":"C", "location":"213", "inscription":"Obitelj Damnjana Jelića"},
"3214":{"field":"C", "location":"214", "inscription":"Obiteljska grobnica Kozina"},
"3215":{"field":"C", "location":"215", "inscription":"Obitelj Boban"},
"3216":{"field":"C", "location":"216", "inscription":"Obitelj Marka - Markoča Grubišića"},
"3217":{"field":"C", "location":"217", "inscription":"Obitelj Petra Grubišića"},
"3218":{"field":"C", "location":"218", "inscription":"Obitelj Ilije Grubišića"},
"3219":{"field":"C", "location":"219", "inscription":"Obitelj Grizelj"},
"3220":{"field":"C", "location":"220", "inscription":"Obitelj Mije Grizelja"},
"3221":{"field":"C", "location":"221", "inscription":"Obitelj Stipe Vlašić"},
"3222":{"field":"C", "location":"222", "inscription":"Vlašić Ambrožić"},
"3223":{"field":"C", "location":"223", "inscription":"Obiteljska grobnica Rafe Boguta"},
"3224":{"field":"C", "location":"224", "inscription":"Obitelj Biško"},
"3225":{"field":"C", "location":"225", "inscription":"Obitelj Milićević"},
"3226":{"field":"C", "location":"226", "inscription":"Obiteljska grobnica Grizelj"},
"3227":{"field":"C", "location":"227", "inscription":"Obitelj Ante Grizelja"},
"3228":{"field":"C", "location":"228", "inscription":"Obiteljska grobnica Ivan Bušić - Pivić"},
"3229":{"field":"C", "location":"229", "inscription":"Obiteljska grobnica Ivan Bušić - Pivić"},
"3230":{"field":"C", "location":"230", "inscription":"Obitelj Mije i Petra Vlašić"},
"3231":{"field":"C", "location":"231", "inscription":"Obitelj Mije i Petra Vlašić"},
"3232":{"field":"C", "location":"232", "inscription":"Obitelj Zdravka Grizelja"},
"3233":{"field":"C", "location":"233", "inscription":"Vječni dom obitelji Mate Grizelja"},
"3234":{"field":"C", "location":"234", "inscription":"Bez oznake"},
"3235":{"field":"C", "location":"235", "inscription":"Obitelj Grizelj"},
"3236":{"field":"C", "location":"236", "inscription":"Frano Knezović"},
"3237":{"field":"C", "location":"237", "inscription":"Obiteljska grobnica Stjepana Vulića"},
"3238":{"field":"C", "location":"238", "inscription":"Mila i Ante Vulić"},
"3239":{"field":"C", "location":"239", "inscription":"Stjepan Vulić"},
"3240":{"field":"C", "location":"240", "inscription":"Obitelj Vulić"},
"3241":{"field":"C", "location":"241", "inscription":"Tihi dom obitelji Vulić"},
"3242":{"field":"C", "location":"242", "inscription":"Bez oznake"},
"3243":{"field":"C", "location":"243", "inscription":"Iva Pejić pok. Ivana"},
"3244":{"field":"C", "location":"244", "inscription":"Bez oznake"},
"3245":{"field":"C", "location":"245", "inscription":"Obitelj Ivana Milićevića"},
"3246":{"field":"C", "location":"246", "inscription":"Obiteljska grobnica Petra - Pešonje Boban"},
"3247":{"field":"C", "location":"247", "inscription":"Obiteljska grobnica Ikana Grubišić"},
"3248":{"field":"C", "location":"248", "inscription":"Obiteljska grobnica Ivana Bobana - Jozića"},
"3249":{"field":"C", "location":"249", "inscription":"Obitelj Boban Ružićevići"},
"3250":{"field":"C", "location":"250", "inscription":"Obitelj Stjepana Grubišić - Paškić"},
"3251":{"field":"C", "location":"251", "inscription":"Obitelj Grizelj Jerko - Egedaš"},
"3252":{"field":"C", "location":"252", "inscription":"Obiteljska grobnica Ivana i Ljube Grizelja (Škočića)"},
"3253":{"field":"C", "location":"253", "inscription":"Obiteljska grobnica Prlić Jerko"},
"3254":{"field":"C", "location":"254", "inscription":"Obiteljska grobnica Prlić Stjepan"},
"3255":{"field":"C", "location":"255", "inscription":"Obiteljska grobnica Prlić Mile - Pejušić"},
"3256":{"field":"C", "location":"256", "inscription":"Obitelj Jerke Grizelj Cvitukić"},
"3257":{"field":"C", "location":"257", "inscription":"Bez oznake"},
"3258":{"field":"C", "location":"258", "inscription":"Kata Grizelj"},
"3259":{"field":"C", "location":"259", "inscription":"Obitelj Blaž Grizelj Džinić"},
"3260":{"field":"C", "location":"260", "inscription":"Obiteljska grobnica Grizelj Mate - Škočića"},
"3261":{"field":"C", "location":"261", "inscription":"Bez oznake"},
"3262":{"field":"C", "location":"262", "inscription":"Obitelj Grizelj"},
"3263":{"field":"C", "location":"263", "inscription":"Obiteljska grobnica Lovre Grizelja"},
"3264":{"field":"C", "location":"264", "inscription":"Obiteljska grobnica Blaža Grizelj"},
"3265":{"field":"C", "location":"265", "inscription":"Bez oznake"},
"3266":{"field":"C", "location":"266", "inscription":"Obitelj Tucak"},
"3267":{"field":"C", "location":"267", "inscription":"Bez oznake"},
"3268":{"field":"C", "location":"268", "inscription":"Obitelj Grizelj"},
"3269":{"field":"C", "location":"269", "inscription":"Krešo | Željka Grizelj"},
"3270":{"field":"C", "location":"270", "inscription":"Obitelj Vlašić Šendeković"},
"3271":{"field":"C", "location":"271", "inscription":"Ante i Cela Vlašić"},
"3272":{"field":"C", "location":"272", "inscription":"Petar Prlić"},
"3273":{"field":"C", "location":"273", "inscription":"Bez oznake"},
"3274":{"field":"C", "location":"274", "inscription":"Obiteljska grobnica Ljube Bošnjaka"},
"3275":{"field":"C", "location":"275", "inscription":"Obitelj Bošnjak"},
"3276":{"field":"C", "location":"276", "inscription":"Ovdje počiva tijela Grizelj Pera"},
"3277":{"field":"C", "location":"277", "inscription":"Obitelj Boban"},
"3278":{"field":"C", "location":"278", "inscription":"Obitelj Zvonke Bobana"},
"3279":{"field":"C", "location":"279", "inscription":"Bez oznake"},
"3280":{"field":"C", "location":"280", "inscription":"Ana Boban"},
"3281":{"field":"C", "location":"281", "inscription":"Obiteljska grobnica Boban"},
"3282":{"field":"C", "location":"282", "inscription":"Obiteljska grobnica Marijana Milićevića"},
"3283":{"field":"C", "location":"283", "inscription":"Obitelj Čuljak - Grizeljušić"},
"3284":{"field":"C", "location":"284", "inscription":"Bez oznake"},
"3285":{"field":"C", "location":"285", "inscription":"Bez oznake"},
"3286":{"field":"C", "location":"286", "inscription":"Prlić Mate i Manda"},
"3287":{"field":"C", "location":"287", "inscription":"Prlić Andrija Ivanov"},
"3288":{"field":"C", "location":"288", "inscription":"Obitelj Vlašić"},
"3289":{"field":"C", "location":"289", "inscription":"Obitelj Stjepana Šimića - Ilijića"},
"3290":{"field":"C", "location":"290", "inscription":"Obiteljska grobnica Ivana Šimić - Kajić"},
"3291":{"field":"C", "location":"291", "inscription":"Obiteljska grobnica Vladimira Grizelja Gumanović"},
"3292":{"field":"C", "location":"292", "inscription":"Bez oznake"},
"3293":{"field":"C", "location":"293", "inscription":"Obitelj Ike Bobana - Jokulovića"},
"3294":{"field":"C", "location":"294", "inscription":"Obitelj Križan Boban - Kićun"},
"3295":{"field":"C", "location":"295", "inscription":"Obitelj Prlić"},
"3296":{"field":"C", "location":"296", "inscription":"Obitelj Prlić"},
"3297":{"field":"C", "location":"297", "inscription":"Obitelj Prlić"},
"3298":{"field":"C", "location":"298", "inscription":"Obitelj Mate Bobana - Ićina"},
"3299":{"field":"C", "location":"299", "inscription":"Vinko Sablje"},
"3300":{"field":"C", "location":"300", "inscription":"Obiteljska grobnica Zdravko Boban"},
"3301":{"field":"C", "location":"301", "inscription":"Ante Vlašić - Dumo"},
"3302":{"field":"C", "location":"302", "inscription":"Bez oznake"},
"3303":{"field":"C", "location":"303", "inscription":"Obiteljska grobnica Dominika Grizelja"},
"3304":{"field":"C", "location":"304", "inscription":"Obiteljska grobnica Ivan Grizelj - Joketa"},
"3305":{"field":"C", "location":"305", "inscription":"Obitelj Jasak"},
"3306":{"field":"C", "location":"306", "inscription":"Obiteljska grobnica Jasak Andrija"},
"3307":{"field":"C", "location":"307", "inscription":"Obitelj Jasak"},
"3308":{"field":"C", "location":"308", "inscription":"Obitelj Jerko Jasak \"Markasović\""},
"3309":{"field":"C", "location":"309", "inscription":"Misir Pavo | Ante"},
"3310":{"field":"C", "location":"310", "inscription":"Misir Jerko | Manda"},
"3311":{"field":"C", "location":"311", "inscription":"Misir Mate | Celija"},
"3312":{"field":"C", "location":"312", "inscription":"Obiteljska grobnica Grizelj (Gumanović)"},
"3313":{"field":"C", "location":"313", "inscription":"Bez oznake"},
"3314":{"field":"C", "location":"314", "inscription":"Bez oznake"},
"3315":{"field":"C", "location":"315", "inscription":"Obiteljska grobnica Slavo Prlić Valdimirov i žena Ljerka"},
"3316":{"field":"C", "location":"316", "inscription":"Obiteljska grobnica Mate Prlić Valdimirov i žena Manda"},
"3317":{"field":"C", "location":"317", "inscription":"Obitelj Vlašić"},
"3318":{"field":"C", "location":"318", "inscription":"Zahvalni Grgišići 2005"},
"3319":{"field":"C", "location":"319", "inscription":"Petar Pejić"},
"3320":{"field":"C", "location":"320", "inscription":"Obiteljska grobnica Ante Bobana Malog"},
"3321":{"field":"C", "location":"321", "inscription":"Obitelj Vlašić"},
"3322":{"field":"C", "location":"322", "inscription":"Obitelj Mate Bobana Matunice"},
"3323":{"field":"C", "location":"323", "inscription":"Mijo Boban - Miško"},
"3324":{"field":"C", "location":"324", "inscription":"Obiteljska grobnica Marko Boban - Marković"},
"3325":{"field":"C", "location":"325", "inscription":"Obitelj Luke Grubišića"},
"3326":{"field":"C", "location":"326", "inscription":"Obitelj Ivana Grubišić"},
"3327":{"field":"C", "location":"327", "inscription":"Obitelj Sablje"},
"3328":{"field":"C", "location":"328", "inscription":"Obiteljska grobnica Benović"},
"3329":{"field":"C", "location":"329", "inscription":"Obitelj Milićević"},
"3330":{"field":"C", "location":"330", "inscription":"Obiteljska grobnica Milana Grizelja"},
"3331":{"field":"C", "location":"331", "inscription":"Jure Grizelj"},
"3332":{"field":"C", "location":"332", "inscription":"Obiteljska grobnica pok. Petra Grizelj"},
"3333":{"field":"C", "location":"333", "inscription":"Obitelj Grizelj - Rašušić"},
"3334":{"field":"C", "location":"334", "inscription":"Bez oznake"},
"3335":{"field":"C", "location":"335", "inscription":"Bogut - Bedronjić"},
"3336":{"field":"C", "location":"336", "inscription":"Obiteljska grobnica Bogut"},
"3337":{"field":"C", "location":"337", "inscription":"Stipan Živić"},
"3338":{"field":"C", "location":"338", "inscription":"Obiteljska grobnica Jakova Vlašića - Ambrožića"},
"3339":{"field":"C", "location":"339", "inscription":"Obitelj Bazina"},
"3340":{"field":"C", "location":"340", "inscription":"Obitelj Grizelj"},
"3341":{"field":"C", "location":"341", "inscription":"Obitelj Andrija Vlašić - Šendeković"},
"3342":{"field":"C", "location":"342", "inscription":"Prlić Ivan - Ikota"},
"3343":{"field":"C", "location":"343", "inscription":"Obitelj Prlić"},
"3344":{"field":"C", "location":"344", "inscription":"Obitelj Rade Prlić"},
"3345":{"field":"C", "location":"345", "inscription":"Obitelj Vlašić"},
"3346":{"field":"C", "location":"346", "inscription":"Obiteljska grobnica Prlić Blaž Zaradić"},
"3347":{"field":"C", "location":"347", "inscription":"Obitelj Vlašić"},
"3348":{"field":"C", "location":"348", "inscription":"Obiteljska grobnica Pejić Tošić"},
"3349":{"field":"C", "location":"349", "inscription":"Obitelj Bože Pejića - Boške"},
"3350":{"field":"C", "location":"350", "inscription":"Bez oznake"},
"3351":{"field":"C", "location":"351", "inscription":"Bez oznake"},
"3352":{"field":"C", "location":"352", "inscription":"Bez oznake"},
"3353":{"field":"C", "location":"353", "inscription":"Bez oznake"},
"3354":{"field":"C", "location":"354", "inscription":"Bez oznake"},
"3355":{"field":"C", "location":"355", "inscription":"Grubišić"},
"3356":{"field":"C", "location":"356", "inscription":"Jurić"},
"3357":{"field":"C", "location":"357", "inscription":"Mila Milićević"},
"3358":{"field":"C", "location":"358", "inscription":"Obiteljska grobnica Stjepan Jurić - Pipe"},
"3359":{"field":"C", "location":"359", "inscription":"Obitelj Stipana Sliškovića"},
"3360":{"field":"C", "location":"360", "inscription":"Obitelj Ante Slišković"},
"3361":{"field":"C", "location":"361", "inscription":"Anka Vlašić"},
"3362":{"field":"C", "location":"362", "inscription":"Obitelj Križan Grizelj - Škočić"},
"3363":{"field":"C", "location":"363", "inscription":"Obiteljska grobnica Mirke Grizelja"},
"3364":{"field":"C", "location":"364", "inscription":"Obitelj Grizelj"},
"3365":{"field":"C", "location":"365", "inscription":"Obitelj Ante Biške"},
"3366":{"field":"C", "location":"366", "inscription":"Obitelj Vlašić"},
"3367":{"field":"C", "location":"367", "inscription":"Bez oznake"},
"3368":{"field":"C", "location":"368", "inscription":"Bez oznake"},
"3369":{"field":"C", "location":"369", "inscription":"Bez oznake"},
"3370":{"field":"C", "location":"370", "inscription":"Obitelj Bošnjak"},
"3371":{"field":"C", "location":"371", "inscription":"Obiteljska grobnica Stjepan Bošnjak - Ćipuka"},
"3372":{"field":"C", "location":"372", "inscription":"Bez oznake"},
"3373":{"field":"C", "location":"373", "inscription":"Obitelj Joze Bošnjaka"},
"3374":{"field":"C", "location":"374", "inscription":"Bez oznake"},
"3375":{"field":"C", "location":"375", "inscription":"Obitelj Mirko Pejić"},
"3376":{"field":"C", "location":"376", "inscription":"Obitelj Petra Čuljka - Perke"},
"3377":{"field":"C", "location":"377", "inscription":"Bez oznake"},
"3378":{"field":"C", "location":"378", "inscription":"Bez oznake"},
"3379":{"field":"C", "location":"379", "inscription":"Anđa Jurić"},
"3380":{"field":"C", "location":"380", "inscription":"Obitelj Misir Mate"},
"3381":{"field":"C", "location":"381", "inscription":"Obitelj Vlado Vlašić - Ambrožić"},
"3382":{"field":"C", "location":"382", "inscription":"Gordana Katušić"},
"3383":{"field":"C", "location":"383", "inscription":"Bez oznake"},
"3384":{"field":"C", "location":"384", "inscription":"Obitelj Stjepan Zorić - Šćepa i sinovi"},
"3385":{"field":"C", "location":"385", "inscription":"Ovdje počivaju Stipan Bogut | Anica Bogut ..."},
"3386":{"field":"C", "location":"386", "inscription":"Obiteljska grobnica Jelić"},
"3387":{"field":"C", "location":"387", "inscription":"Obitelj Vlašić"},
"3388":{"field":"C", "location":"388", "inscription":"Obitelj Vlašić"},
"3389":{"field":"C", "location":"389", "inscription":"Obitelj Anđelka Vlašića"},
"3390":{"field":"C", "location":"390", "inscription":"Vlašić"},
"3391":{"field":"C", "location":"391", "inscription":"Bez oznake"},
"3392":{"field":"C", "location":"392", "inscription":"Ante i Iva Boban"},
"3393":{"field":"C", "location":"393", "inscription":"Obitelj Drage Vlašića \"Galešić\""},
"3394":{"field":"C", "location":"394", "inscription":"Ivan Vlašić"},
"3395":{"field":"C", "location":"395", "inscription":"Obitelj Vlašić"},
"3396":{"field":"C", "location":"396", "inscription":"Obitelj Stipić"},
"3397":{"field":"C", "location":"397", "inscription":"Obiteljska grobnica Joze Crnogorca"},
"3398":{"field":"C", "location":"398", "inscription":"Obitelj Spajić"},
"3399":{"field":"C", "location":"399", "inscription":"Bez oznake"},
"3400":{"field":"C", "location":"400", "inscription":"Obiteljska grobnica Zdravko Bošnjak"},
"3401":{"field":"C", "location":"401", "inscription":"Obitelj Ivana Pejića"},
"3402":{"field":"C", "location":"402", "inscription":"Obitelj Pejić"},
"3403":{"field":"C", "location":"403", "inscription":"Obiteljska grobnica Mate - Maše Pejića"},
"3404":{"field":"C", "location":"404", "inscription":"Obiteljska grobnica Čuljak Ivan - Đomin"},
"3405":{"field":"C", "location":"405", "inscription":"Obiteljska grobnica Šimić Stipan - Ćipa"},
"3406":{"field":"C", "location":"406", "inscription":"Obiteljska grobnica Pejić Ivana - Mačin"},
"3407":{"field":"C", "location":"407", "inscription":"Obitelj Mate Jelić"},
"3408":{"field":"C", "location":"408", "inscription":"Stjepan Grizelj"},
"3409":{"field":"C", "location":"409", "inscription":"Obitelj Grizelj"},
"3410":{"field":"C", "location":"410", "inscription":"Obitelj Mire Misira - Špičić | Bogut"},
"3411":{"field":"C", "location":"411", "inscription":"Obitelj Frane - Vranješke Zorića"},
"3412":{"field":"C", "location":"412", "inscription":"Balić"},
"3413":{"field":"C", "location":"413", "inscription":"Obiteljska grobnica Damjana Vlašić"},
"3414":{"field":"C", "location":"414", "inscription":"Obitelj Zvonke Vlašića"},
"3415":{"field":"C", "location":"415", "inscription":"Obiteljska grobnica Jakova Vlašića"},
"3416":{"field":"C", "location":"416", "inscription":"Obiteljska grobnica Martina Vlašića Vranješevića"},
"3417":{"field":"C", "location":"417", "inscription":"Jurka Grizelj"},
"3418":{"field":"C", "location":"418", "inscription":"Obitelj Šumić"},
"3419":{"field":"C", "location":"419", "inscription":"Bez oznake"},
"3420":{"field":"C", "location":"420", "inscription":"Diže Križan i Mate sa stricom...Prlić"},
"3421":{"field":"C", "location":"421", "inscription":"Obiteljska grobnica Pejić Stipana"},
"3422":{"field":"C", "location":"422", "inscription":"Ovdje počiva tijelo Matije Prlić | Mate i Zorka Boban"},
"3423":{"field":"C", "location":"423", "inscription":"Obitelj Mikulić"},
"3424":{"field":"C", "location":"424", "inscription":"Obiteljska grobnica Mate Mikulića"},
"3425":{"field":"C", "location":"425", "inscription":"Bez oznake"},
"3426":{"field":"C", "location":"426", "inscription":"Bez oznake"},
"3427":{"field":"C", "location":"427", "inscription":"Ovdje počiva tijelo Prlić Janje i Stipana"},
"3428":{"field":"C", "location":"428", "inscription":"Bez oznake"},
"3429":{"field":"C", "location":"429", "inscription":"Obitelj Stjepana Pejića"},
"3430":{"field":"C", "location":"430", "inscription":"Bez oznake"},
"3431":{"field":"C", "location":"431", "inscription":"Obiteljska grobnica Pejić"},
"3432":{"field":"C", "location":"432", "inscription":"Bez oznake"},
"3433":{"field":"C", "location":"433", "inscription":"Obitelj Pejić"},
"3434":{"field":"C", "location":"434", "inscription":"Obitelj Barić"},
"3435":{"field":"C", "location":"435", "inscription":"Obiteljska grobnica Misir Jure"},
"3436":{"field":"C", "location":"436", "inscription":"Obitelj Bazina"},
"3437":{"field":"C", "location":"437", "inscription":"Obitelj Grizelj - Čamprić"},
"3438":{"field":"C", "location":"438", "inscription":"Obitelj Branko Šimić - Pukić"},
"3439":{"field":"C", "location":"439", "inscription":"Petar i Pava Vlašić"},
"3440":{"field":"C", "location":"440", "inscription":"Mate Vlašić"},
"3441":{"field":"C", "location":"441", "inscription":"Obitelj Joze Vlašića - Jozan"},
"3442":{"field":"C", "location":"442", "inscription":"Obiteljska grobnica Ivana Vlašića - Ikelje"},
"3443":{"field":"C", "location":"443", "inscription":"Obiteljska grobnica Ante Vlašić"},
"3444":{"field":"C", "location":"444", "inscription":"Vlašić"},
"3445":{"field":"C", "location":"445", "inscription":"Obitelj Ivana Tucaka"},
"3446":{"field":"C", "location":"446", "inscription":"Obitelj Dinke Prlića"},
"3447":{"field":"C", "location":"447", "inscription":"Obiteljska grobnica Prlić Ivan - Đidova"},
"3448":{"field":"C", "location":"448", "inscription":"Bošnjak"},
"3449":{"field":"C", "location":"449", "inscription":"Šimić Venka"},
"3450":{"field":"C", "location":"450", "inscription":"Obitelj Petra Šimića - Lole"},
"3451":{"field":"C", "location":"451", "inscription":"Ovdje počiva tijelo Pejić Matija"},
"3452":{"field":"C", "location":"452", "inscription":"Obitelj Pejić Gošparević"},
"3453":{"field":"C", "location":"453", "inscription":"Stipe i Iva Pejić"},
"3454":{"field":"C", "location":"454", "inscription":"Obitelj Stjepana Pejića - Belilovića"},
"3455":{"field":"C", "location":"455", "inscription":"Obitelj Grubišić"},
"3456":{"field":"C", "location":"456", "inscription":"Pejić - Bobanušić"},
"3457":{"field":"C", "location":"457", "inscription":"Obiteljska grobnica Mate Pejić - Mateljak"},
"3458":{"field":"C", "location":"458", "inscription":"Obitelj Bazina - Banović"},
"3459":{"field":"C", "location":"459", "inscription":"Obitelj Jerke Bazina Banović"},
"3460":{"field":"C", "location":"460", "inscription":"Obitelj Marijan Vlašić"},
"3461":{"field":"C", "location":"461", "inscription":"Obiteljska grobnica Gabro Grizelj"},
"3462":{"field":"C", "location":"462", "inscription":"Obitelj Ante Vlašić - Ambrožić"},
"3463":{"field":"C", "location":"463", "inscription":"Bez oznake"},
"3464":{"field":"C", "location":"464", "inscription":"Obitelj Branko Jurić"},
"3465":{"field":"C", "location":"465", "inscription":"Ovdje počivaju Anica | Jozo | Veselko Vlašić"},
"3466":{"field":"C", "location":"466", "inscription":"U miru počiva obitelj Vlašić"},
"3467":{"field":"C", "location":"467", "inscription":"Bez oznake"},
"3468":{"field":"C", "location":"468", "inscription":"Bez oznake"},
"3469":{"field":"C", "location":"469", "inscription":"Očekuju uskrsnuće tijela i život vječni Vlašić"},
"3470":{"field":"C", "location":"470", "inscription":"Bez oznake"},
"3471":{"field":"C", "location":"471", "inscription":"Grobnica obitelji \"Vabić\" Prlić"},
"3472":{"field":"C", "location":"472", "inscription":"Stipan Prlić"},
"3473":{"field":"C", "location":"473", "inscription":"Obitelj Stjepana Prlića Ćopera"},
"3474":{"field":"C", "location":"474", "inscription":"Nikola Pejić - Pipanović"},
"3475":{"field":"C", "location":"475", "inscription":"Obitelj Bušić - Veka"},
"3476":{"field":"C", "location":"476", "inscription":"Mate | Ivan | Ruža Prlić"},
"3477":{"field":"C", "location":"477", "inscription":"Anica Prlić udata Boban | Zorka"},
"3478":{"field":"C", "location":"478", "inscription":"Obiteljska grobnica Zdravko Prlić"},
"3479":{"field":"C", "location":"479", "inscription":"Obitelj Šimić"},
"3480":{"field":"C", "location":"480", "inscription":"Knezović"},
"3481":{"field":"C", "location":"481", "inscription":"Obiteljska grobnica Frane Knezovića"},
"3482":{"field":"C", "location":"482", "inscription":"Jerko Šimić"},
"3483":{"field":"C", "location":"483", "inscription":"Ferdo Čuljak"},
"3484":{"field":"C", "location":"484", "inscription":"Obitelj Jakova Pejića"},
"3485":{"field":"C", "location":"485", "inscription":"Obiteljska grobnica Vrane - Vranine Vlašić"},
"3486":{"field":"C", "location":"486", "inscription":"Obiteljska grobnica Vlašić Vinka"},
"3487":{"field":"C", "location":"487", "inscription":"Obitelj Vlašić"},
"3488":{"field":"C", "location":"488", "inscription":"Obitelj Vlašić (Antasović)"},
"3489":{"field":"C", "location":"489", "inscription":"Obitelj Pere Grizelja - Pepe"},
"3490":{"field":"C", "location":"490", "inscription":"Obitelj Prlić - Grgišić"},
"3491":{"field":"C", "location":"491", "inscription":"Obitelj Ivana Prlića"},
"3492":{"field":"C", "location":"492", "inscription":"Obitelj Mire Šimića"},
"3493":{"field":"C", "location":"493", "inscription":"Obitelj Milićević"},
"3494":{"field":"C", "location":"494", "inscription":"Obitelj Pejić - Jakišić"},
"3495":{"field":"C", "location":"495", "inscription":"Bez oznake"},
"3496":{"field":"C", "location":"496", "inscription":"Ovdje počiva tijelo Vlašić Grge"},
"3497":{"field":"C", "location":"497", "inscription":"Obitelj Slave Šimića - Kuvarević"},
"3498":{"field":"C", "location":"498", "inscription":"Obitelj Šimić - Jozman"},
"3499":{"field":"C", "location":"499", "inscription":"Obiteljska grobnica Šimić Nikole Bepić"},
"3500":{"field":"C", "location":"500", "inscription":"Obiteljska grobnica Mate Pejića - Čačka"},
"3501":{"field":"C", "location":"501", "inscription":"Obiteljska grobnica Mate Pejića - Čačka"},
"3502":{"field":"C", "location":"502", "inscription":"Obitelj Prlić Stjepana - Šepelja"},
"3503":{"field":"C", "location":"503", "inscription":"Misir Anđa Stipana"},
"3504":{"field":"C", "location":"504", "inscription":"Obitelj Petra Milićevića"},
"3505":{"field":"C", "location":"505", "inscription":"Šimun Spajić"},
"3506":{"field":"C", "location":"506", "inscription":"Ivan Bešlić pok. Ivana"},
"3507":{"field":"C", "location":"507", "inscription":"Obitelj Jure Čuljka"},
"3508":{"field":"C", "location":"508", "inscription":"Bošnjak"},
"3509":{"field":"C", "location":"509", "inscription":"Obitelj Stjepana Grizelja - Purkića"},
"3510":{"field":"C", "location":"510", "inscription":"Vječni dom obitelji Ljube Biške"},
"3511":{"field":"C", "location":"511", "inscription":"Obitelj Biško"},
"3512":{"field":"C", "location":"512", "inscription":"Obitelj Žare Prlića"},
"3513":{"field":"C", "location":"513", "inscription":"Spomenik podiže žena Luca"},
"3514":{"field":"C", "location":"514", "inscription":"Bez oznake"},
"3515":{"field":"C", "location":"515", "inscription":"Kata Vlašić"},
"3516":{"field":"C", "location":"516", "inscription":"Vlašić Dragica | Jerko"},
"3517":{"field":"C", "location":"517", "inscription":"Vlašić Jozo Aniša i Marko"},
"3518":{"field":"C", "location":"518", "inscription":"Obitelj Mirke i Vrane Vlašić"},
"3519":{"field":"C", "location":"519", "inscription":"Vlašić Alen | Dominik | Iva"},
"3520":{"field":"C", "location":"520", "inscription":"Obitelj Ivana Vlašića - Bana"},
"3521":{"field":"C", "location":"521", "inscription":"Obiteljska grobnica Stjepan Prlić - Šćepa"},
"3522":{"field":"C", "location":"522", "inscription":"Obitelj Ivan Prlić - Ćika"},
"3523":{"field":"C", "location":"523", "inscription":"Obitelj Vinko Šimić - Žutković"},
"3524":{"field":"C", "location":"524", "inscription":"Obiteljska grobnica Stjepan Šimić - Žutković"},
"3525":{"field":"C", "location":"525", "inscription":"Obitelj Mile Šimić - Vraneškić"},
"3526":{"field":"C", "location":"526", "inscription":"Obitelj Mile Benović"},
"3527":{"field":"C", "location":"527", "inscription":"Obitelj Filim - Filip Prlić"},
"3528":{"field":"C", "location":"528", "inscription":"Obiteljska grobnica Janja i Jerko Prlić"},
"3529":{"field":"C", "location":"529", "inscription":"Obitelj Stjepana Prlić - Ćućan"},
"3530":{"field":"C", "location":"530", "inscription":"Ovdje počiva tijelo Matije Prlić"},
"3531":{"field":"C", "location":"531", "inscription":"Obitelj Prlić"},
"3532":{"field":"C", "location":"532", "inscription":"Vinko Prlić"},
"3533":{"field":"C", "location":"533", "inscription":"AV"},
"3534":{"field":"C", "location":"534", "inscription":"Obitelj Andrije - Andre Pejić"},
"3535":{"field":"C", "location":"535", "inscription":"Obitelj Boban - Zelić"},
"3536":{"field":"C", "location":"536", "inscription":"Obitelj Vlašić"},
"3537":{"field":"C", "location":"537", "inscription":"Obitelj Vlašić - Barišić"},
"3538":{"field":"C", "location":"538", "inscription":"Bez oznake"},
"3539":{"field":"C", "location":"539", "inscription":"Ilija | Stana Boban"},
"3540":{"field":"C", "location":"540", "inscription":"Obitelj Prlić - Bilonjić"},
"3541":{"field":"C", "location":"541", "inscription":"Bez oznake"},
"3542":{"field":"C", "location":"542", "inscription":"Mila | Matija Šimić"},
"3543":{"field":"C", "location":"543", "inscription":"Bez oznake"},
"3544":{"field":"C", "location":"544", "inscription":"Obitelj Slobodana Šimića - Pukića"},
"3545":{"field":"C", "location":"545", "inscription":"Obitelj Stjepana Šimića"},
"3546":{"field":"C", "location":"546", "inscription":"Obiteljska grobnica Križana Vlašića"},
"3547":{"field":"C", "location":"547", "inscription":"Obitelj Ivan Boban Mišić"},
"3548":{"field":"C", "location":"548", "inscription":"Obiteljska Grobnica Šimić Ivana Matić"},
"3549":{"field":"C", "location":"549", "inscription":"Obitelj Prlić"},
"3550":{"field":"C", "location":"550", "inscription":"Bez oznake"},
"3551":{"field":"C", "location":"551", "inscription":"Ljuba Prlić"},
"3552":{"field":"C", "location":"552", "inscription":"Tihi dom obitelji Boban (Coković)"},
"3553":{"field":"C", "location":"553", "inscription":"Bez oznake"},
"3554":{"field":"C", "location":"554", "inscription":"Obitelj Jerko Pejić - Ćukić"},
"3555":{"field":"C", "location":"555", "inscription":"Obitelj Marko Pejić - Ćukić"},
"3556":{"field":"C", "location":"556", "inscription":"Obiteljska grobnica Ivan Šimić - Matišić"},
"3557":{"field":"C", "location":"557", "inscription":"Obitelj Ivana Vlašića - Dunđića"},
"3558":{"field":"C", "location":"558", "inscription":"Obiteljska grobnica Petra Vlašić"},
"3559":{"field":"C", "location":"559", "inscription":"Obitelj Ikasa Benovića"},
"3560":{"field":"C", "location":"560", "inscription":"Obiteljska grobnica Ivan Šimić Jojić"},
"3561":{"field":"C", "location":"561", "inscription":"Obitelj Ferde Šimića"},
"3562":{"field":"C", "location":"562", "inscription":"Bez oznake"},
"3563":{"field":"C", "location":"563", "inscription":"Obitelj Vlašić"},
"3564":{"field":"C", "location":"564", "inscription":"Vlašić Ljuba | Slavo | Anđa"},
"3565":{"field":"C", "location":"565", "inscription":"Obitelj Ferde Vlašić"},
"3566":{"field":"C", "location":"566", "inscription":"Obitelj Josipa i Ilke Vlašić"},
"3567":{"field":"C", "location":"567", "inscription":"Obiteljska grobnica Andrija Vlašića"},
"3568":{"field":"C", "location":"568", "inscription":"Prlić"},
"3569":{"field":"C", "location":"569", "inscription":"Vječni dom obitelji Danke Prlića"},
"3570":{"field":"C", "location":"570", "inscription":"Obitelj Prlić"},
"3571":{"field":"C", "location":"571", "inscription":"Obitelj Sablje"},
"3572":{"field":"C", "location":"572", "inscription":"Obiteljska grobnica Klare Boban - Librušić"},
"3573":{"field":"C", "location":"573", "inscription":"Jure i Mara Boban"},
"3574":{"field":"C", "location":"574", "inscription":"Vječni dom obitelji Mate - Maće Prlića"},
"3575":{"field":"C", "location":"575", "inscription":"Obitelj Ivan Pejić Mijić"},
"3576":{"field":"C", "location":"576", "inscription":"Obiteljska grobnica Mile Vlašića"},
"3577":{"field":"C", "location":"577", "inscription":"Obitelj Boban - Biketić"},
"3578":{"field":"C", "location":"578", "inscription":"Obitelj Vlade Bobana"},
"3579":{"field":"C", "location":"579", "inscription":"Ovdje počiva Mate Pejić"},
"3580":{"field":"C", "location":"580", "inscription":"Obitelj Mile Knezovića"},
"3581":{"field":"C", "location":"581", "inscription":"Obiteljska grobnica Šimić"},
"3582":{"field":"C", "location":"582", "inscription":"Obitelj Jerke Šimić - Matišić"},
"3583":{"field":"C", "location":"583", "inscription":"Obitelj Petra Šimić - Matišić"},
"3584":{"field":"C", "location":"584", "inscription":"Obiteljska grobnica Ivana Pejića - Draganovića"},
"3585":{"field":"C", "location":"585", "inscription":"Dragan Pejić"},
"3586":{"field":"C", "location":"586", "inscription":"Obitelj Frane Šimića - Pekića"},
"3587":{"field":"C", "location":"587", "inscription":"Bez oznake"},
"3588":{"field":"C", "location":"588", "inscription":"Obitelj Vlašić"},
"3589":{"field":"C", "location":"589", "inscription":"Obitelj Prlić - Grgišić"},
"3590":{"field":"C", "location":"590", "inscription":"Vlašić Marija | Petar | Iva | Vid"},
"3591":{"field":"C", "location":"591", "inscription":"Obitelj Bošnjak"},
"3592":{"field":"C", "location":"592", "inscription":"Prlić Frano | supruga Joza rođ. Spajić"},
"3593":{"field":"C", "location":"593", "inscription":"Obiteljska grobnica Mate Grizelj - Pavlović"},
"3594":{"field":"C", "location":"594", "inscription":"Čuljak Križan - Križe"},
"3595":{"field":"C", "location":"595", "inscription":"Obitelj Ante Vlašić"},
"3596":{"field":"C", "location":"596", "inscription":"Obiteljska grobnica Rafe Vlašića"},
"3597":{"field":"C", "location":"597", "inscription":"Bez oznake"},
"3598":{"field":"C", "location":"598", "inscription":"Obiteljska grobnica Bošnjak"},
"3599":{"field":"C", "location":"599", "inscription":"Obiteljska grobnica Prlić"},
"3600":{"field":"C", "location":"600", "inscription":"Obitelj Jage Bobana (Ikeljića)"},
"3601":{"field":"C", "location":"601", "inscription":"Obitelj Vlade Bobana - Bikotića"},
"3602":{"field":"C", "location":"602", "inscription":"Tihi dom obitelji Boban"},
"3603":{"field":"C", "location":"603", "inscription":"Obitelj Ante Šimića"},
"3604":{"field":"C", "location":"604", "inscription":"Obitelj Pejić Stipan"},
"3605":{"field":"C", "location":"605", "inscription":"Obiteljska grobnica Mile Čuljak"},
"3606":{"field":"C", "location":"606", "inscription":"Obitelj Mirka Pejića"},
"3607":{"field":"C", "location":"607", "inscription":"Ovdje počiva tijelo Pejić Ivana"},
"4001":{"field":"D", "location":"1", "inscription":"Predsjednik HR. Herceg Bosne Mr. Mate Boban"},
"4002":{"field":"D", "location":"2", "inscription":"Bez oznake"},
"4003":{"field":"D", "location":"3", "inscription":"Obitelj Prlić"},
"4004":{"field":"D", "location":"4", "inscription":"Obitelj Jerka Benović"},
"4005":{"field":"D", "location":"5", "inscription":"Bez oznake"},
"4006":{"field":"D", "location":"6", "inscription":"Bez oznake"},
"4007":{"field":"D", "location":"7", "inscription":"Obitelj Ljube Bobana - Galina -"},
"4008":{"field":"D", "location":"8", "inscription":"Obitelj Soldo"},
"4009":{"field":"D", "location":"9", "inscription":"Obiteljska grobnica Vokić"},
"4010":{"field":"D", "location":"10", "inscription":"Obitelj Blaža Vlašić"},
"4011":{"field":"D", "location":"11", "inscription":"Obitelj Vlašić"},
"4012":{"field":"D", "location":"12", "inscription":"Obitelj Prlić Zdravko - Gago -"},
"4013":{"field":"D", "location":"13", "inscription":"Obitelj Srećka Vlašića"},
"4014":{"field":"D", "location":"14", "inscription":"Obitelj Jerke Vlašića \"Ambrožić\""},
"4015":{"field":"D", "location":"15", "inscription":"Obitelj Adolfa Prlića"},
"4016":{"field":"D", "location":"16", "inscription":"Bez oznake"},
"4017":{"field":"D", "location":"17", "inscription":"Obitelj Eduarda (Ede) Prlić"},
"4018":{"field":"D", "location":"18", "inscription":"Boban"},
"4019":{"field":"D", "location":"19", "inscription":"Bogut"},
"4020":{"field":"D", "location":"20", "inscription":"Obitelj Vokić"},
"4021":{"field":"D", "location":"21", "inscription":"Bez oznake"},
"4022":{"field":"D", "location":"22", "inscription":"Obitelj Jerke Prlića"},
"4023":{"field":"D", "location":"23", "inscription":"Obitelj Jerke Vokića"},
"4024":{"field":"D", "location":"24", "inscription":"Obitelj Vlašić"},
"4025":{"field":"D", "location":"25", "inscription":"Obitelj Biško"},
"4026":{"field":"D", "location":"26", "inscription":"Obitelj Mate Prlić \"Mateva\""},
"4027":{"field":"D", "location":"27", "inscription":"Obitelj Ante Biško - Bakula"},
"4028":{"field":"D", "location":"28", "inscription":"Bez oznake"},
"4029":{"field":"D", "location":"29", "inscription":"Obiteljska grobnica Vinka Solde"},
"4030":{"field":"D", "location":"30", "inscription":"Orban"},
"4031":{"field":"D", "location":"31", "inscription":"Obitelj Ante Grizelja"},
"4032":{"field":"D", "location":"32", "inscription":"Obiteljska grobnica Prlić"},
"4033":{"field":"D", "location":"33", "inscription":"Obitelj Bošnjak Ivan"},
"4034":{"field":"D", "location":"34", "inscription":"Obitelj Prlić - Grgišić"},
"4035":{"field":"D", "location":"35", "inscription":"Obitelj Ante Kozina"},
"4036":{"field":"D", "location":"36", "inscription":"Obitelj Mate Boban - Galin"},
"4037":{"field":"D", "location":"37", "inscription":"Obitelj Vinka Bobana"},
"4038":{"field":"D", "location":"38", "inscription":"Obitelj Zvonko Pejić - Knezovušić"},
"4039":{"field":"D", "location":"39", "inscription":"Bez oznake"},
"4040":{"field":"D", "location":"40", "inscription":"Obitelj Petra Bobana"},
"4041":{"field":"D", "location":"41", "inscription":"Bez oznake"},
"4042":{"field":"D", "location":"42", "inscription":"Obitelj Pejić"},
"4043":{"field":"D", "location":"43", "inscription":"Grizelj"},
"4044":{"field":"D", "location":"44", "inscription":"Obitelj Šimić"},
"4045":{"field":"D", "location":"45", "inscription":"Bez oznake"},
"4046":{"field":"D", "location":"46", "inscription":"Obitelj Andrije Boguta"},
"4047":{"field":"D", "location":"47", "inscription":"Obitelj Ante Boguta"},
"4048":{"field":"D", "location":"48", "inscription":"Obitelj Vlašić"},
"4049":{"field":"D", "location":"49", "inscription":"Obitelj Jerke i Mile Grubišić"},
"4050":{"field":"D", "location":"50", "inscription":"Obiteljska grobnica Ivana Bušića"},
"4051":{"field":"D", "location":"51", "inscription":"Obiteljska grobnica Marinka Benovića"},
"4052":{"field":"D", "location":"52", "inscription":"Obitelj Pejić \"Bobanušić\""},
"4053":{"field":"D", "location":"53", "inscription":"Obitelj Dinka Bazine"},
"4054":{"field":"D", "location":"54", "inscription":"Obitelj Ljube Šimića \"Žutkovića\""},
"4055":{"field":"D", "location":"55", "inscription":"Obitelj Ferde Vlašića"},
"4056":{"field":"D", "location":"56", "inscription":"Bez oznake"},
"4057":{"field":"D", "location":"57", "inscription":"Obitelj Bogut"},
"4058":{"field":"D", "location":"58", "inscription":"Obitelj Jure Grizelja - Garanovića -"},
"4059":{"field":"D", "location":"59", "inscription":"Obitelj Zorić"},
"4060":{"field":"D", "location":"60", "inscription":"Bez oznake"},
"4061":{"field":"D", "location":"61", "inscription":"Obitelj Boban"},
"4062":{"field":"D", "location":"62", "inscription":"Obitelj Markotić"},
"4063":{"field":"D", "location":"63", "inscription":"Obitelj Ivana Zorića"},
"4064":{"field":"D", "location":"64", "inscription":"Obitelj Boban"},
"4065":{"field":"D", "location":"65", "inscription":"Obitelj Željko Pejić - Bobanušić"},
"4066":{"field":"D", "location":"66", "inscription":"Bez oznake"},
"4067":{"field":"D", "location":"67", "inscription":"Obitelj Pejić"},
"4068":{"field":"D", "location":"68", "inscription":"Bez oznake"},
"4069":{"field":"D", "location":"69", "inscription":"Obitelj Pejić - Bobanušić"},
"4070":{"field":"D", "location":"70", "inscription":"Obitelj Jasak"},
"4071":{"field":"D", "location":"71", "inscription":"Obitelj Širić"},
"4072":{"field":"D", "location":"72", "inscription":"Obitelj Mladena Šimića - Jojića"},
"4073":{"field":"D", "location":"73", "inscription":"Obitelj Stjepana Vlašića"},
"4074":{"field":"D", "location":"74", "inscription":"Obitelj Vinka Vlašića"},
"4075":{"field":"D", "location":"75", "inscription":"Bez oznake"},
"4076":{"field":"D", "location":"76", "inscription":"Obitelj Prlić - Vicarić"},
"4077":{"field":"D", "location":"77", "inscription":"Obitelj Pejić - Bošković"},
"4078":{"field":"D", "location":"78", "inscription":"Obitelj Jure Zorića"},
"4079":{"field":"D", "location":"79", "inscription":"Obitelj Boban"},
"4080":{"field":"D", "location":"80", "inscription":"Bez oznake"},
"4081":{"field":"D", "location":"81", "inscription":"Prlić"},
"4082":{"field":"D", "location":"82", "inscription":"Bez oznake"},
"4083":{"field":"D", "location":"83", "inscription":"Bez oznake"},
"4084":{"field":"D", "location":"84", "inscription":"Obitelj Bošnjak"},
"4085":{"field":"D", "location":"85", "inscription":"Bez oznake"},
"4086":{"field":"D", "location":"86", "inscription":"Obitelj Grizelj"},
"4087":{"field":"D", "location":"87", "inscription":"Obitelj Jerko Grubišić - Crni"},
"4088":{"field":"D", "location":"88", "inscription":"Obitelj Slavka Prlića"},
"4089":{"field":"D", "location":"89", "inscription":"Bez oznake"},
"4090":{"field":"D", "location":"90", "inscription":"Obitelj Mate Grizelj - Joketić"},
"4091":{"field":"D", "location":"91", "inscription":"Bez oznake"},
"4092":{"field":"D", "location":"92", "inscription":"Obitelj Damnjan - Dane Boban"},
"4093":{"field":"D", "location":"93", "inscription":"Bez oznake"},
"4094":{"field":"D", "location":"94", "inscription":"Bošnjak"},
"5001":{"field":"E", "location":"1", "inscription":"Obitelj Vlašić"},
"5002":{"field":"E", "location":"2", "inscription":"Vlašić Stjepan - Galešić"},
"5003":{"field":"E", "location":"3", "inscription":"Stjepan Boban - Rapišić"},
"5004":{"field":"E", "location":"4", "inscription":"Obitelj Grizelj"},
"5005":{"field":"E", "location":"5", "inscription":"Bez oznake"},
"5006":{"field":"E", "location":"6", "inscription":"Obitelj Boban-Ivišević"},
"5007":{"field":"E", "location":"7", "inscription":"Obiteljska grobnica Frane Boban - Zelić"},
"5008":{"field":"E", "location":"8", "inscription":"Obitelj Šimuna Boban"},
"5009":{"field":"E", "location":"9", "inscription":"Obiteljska grobnica Boban"},
"5010":{"field":"E", "location":"10", "inscription":"Bez oznake"},
"5011":{"field":"E", "location":"11", "inscription":"Obiteljska grobnica Nikole Boban"},
"5012":{"field":"E", "location":"12", "inscription":"Obiteljska grobnica Stjepana Grubišića"},
"5013":{"field":"E", "location":"13", "inscription":"Obiteljska grobnica Prlić"},
"5014":{"field":"E", "location":"14", "inscription":"Obiteljska grobnica Ivana - Ike Crnogorca"},
"5015":{"field":"E", "location":"15", "inscription":"Bez oznake"},
"5016":{"field":"E", "location":"16", "inscription":"Bez oznake"},
"5017":{"field":"E", "location":"17", "inscription":"Obitelj Jure Grizelj Purkić"},
"5018":{"field":"E", "location":"18", "inscription":"Bez oznake"},
"5019":{"field":"E", "location":"19", "inscription":"Šimić"},
"5020":{"field":"E", "location":"20", "inscription":"Obitelj Lovre Pejića (Ćukića)"},
"5021":{"field":"E", "location":"21", "inscription":"Obitelj Šimić - Bralić"},
"5022":{"field":"E", "location":"22", "inscription":"Obitelj Križana Šimić - Zele"},
"5023":{"field":"E", "location":"23", "inscription":"Obiteljska grobnica Mikulić Ivana"},
"5024":{"field":"E", "location":"24", "inscription":"Iva Mikulić"},
"5025":{"field":"E", "location":"25", "inscription":"Obitelj Milićević - Jokulović -"},
"5026":{"field":"E", "location":"26", "inscription":"Mikulić Petar i Mate"},
"5027":{"field":"E", "location":"27", "inscription":"Bez oznake"},
"5028":{"field":"E", "location":"28", "inscription":"Obitelj Grizelj-Zelić"},
"5029":{"field":"E", "location":"29", "inscription":"Obitelj Boban"},
"5030":{"field":"E", "location":"30", "inscription":"Obiteljska grobnica Jerko Boban-Zele"},
"5031":{"field":"E", "location":"31", "inscription":"Obitelj Boban"},
"5032":{"field":"E", "location":"32", "inscription":"Boban"},
"5033":{"field":"E", "location":"33", "inscription":"Obitelj Vinka Crnogorca"},
"5034":{"field":"E", "location":"34", "inscription":"Obiteljska grobnica Mate Bošnjaka"},
"5035":{"field":"E", "location":"35", "inscription":"Prlić"},
"5036":{"field":"E", "location":"36", "inscription":"Obiteljska grobnica Marka Pejića"},
"5037":{"field":"E", "location":"37", "inscription":"Marinko Šimić"},
"5038":{"field":"E", "location":"38", "inscription":"Bez oznake"},
"5039":{"field":"E", "location":"39", "inscription":"Obitelj Ante Pejića"},
"5040":{"field":"E", "location":"40", "inscription":"Obitelj Petra Pejića - Žerića"},
"5041":{"field":"E", "location":"41", "inscription":"Obitelj Ante Pejić - Bobanušić"},
"5042":{"field":"E", "location":"42", "inscription":"Obiteljska grobnica Ivana i Željke Pejića"},
"5043":{"field":"E", "location":"43", "inscription":"Jakov Grizelj Anđa Grizelj"},
"5044":{"field":"E", "location":"44", "inscription":"Obitelj Pejić - Mušanović"},
"5045":{"field":"E", "location":"45", "inscription":"Obitelj Blage Bobana"},
"5046":{"field":"E", "location":"46", "inscription":"Obiteljska grobnica Šimić Ljube/Pekić"},
"5047":{"field":"E", "location":"47", "inscription":"Obitelj Benović"},
"5048":{"field":"E", "location":"48", "inscription":"Obitelj Luka-Lukša Boban"},
"5049":{"field":"E", "location":"49", "inscription":"Obitelj Jerke Bobana"},
"5050":{"field":"E", "location":"50", "inscription":"Obiteljska grobnica Boban Franje"},
"5051":{"field":"E", "location":"51", "inscription":"Prlić"},
"5052":{"field":"E", "location":"52", "inscription":"Obiteljska grobnica Rafe Pejića - Rapića"},
"5053":{"field":"E", "location":"53", "inscription":"Leventić Prlić"},
"5054":{"field":"E", "location":"54", "inscription":"Benović"},
"5055":{"field":"E", "location":"55", "inscription":"Obiteljska grobnica Vlado Pejić - Jerkić"},
"5056":{"field":"E", "location":"56", "inscription":"Obitelj Marić - Gadžić"},
"5057":{"field":"E", "location":"57", "inscription":"Obitelj Ante - Pave Bobana"},
"5058":{"field":"E", "location":"58", "inscription":"Bez oznake"},
"5059":{"field":"E", "location":"59", "inscription":"Obitelj Zorić"},
"5060":{"field":"E", "location":"60", "inscription":"Zorić"},
"5061":{"field":"E", "location":"61", "inscription":"Obitelj Šimić - Matišić"},
"5062":{"field":"E", "location":"62", "inscription":"Bez oznake"},
"5063":{"field":"E", "location":"63", "inscription":"Obitelj Ivana i Mirka Bobana"},
"5064":{"field":"E", "location":"64", "inscription":"Boban"},
"5065":{"field":"E", "location":"65", "inscription":"Bez oznake"},
"5066":{"field":"E", "location":"66", "inscription":"Boban"},
"5067":{"field":"E", "location":"67", "inscription":"Obiteljska grobnica Cvitana Boban"},
"5068":{"field":"E", "location":"68", "inscription":"Petar Šimić-Šabanović"},
"5069":{"field":"E", "location":"69", "inscription":"Grubišić Ivanu - Ikuli"},
"5070":{"field":"E", "location":"70", "inscription":"Obitelj Stjepana Šimića-Štefeka-"},
"5071":{"field":"E", "location":"71", "inscription":"Obitelj Ivan Šimić"},
"5072":{"field":"E", "location":"72", "inscription":"Bošnjak"},
"5073":{"field":"E", "location":"73", "inscription":"Obiteljska grobnica Mate Čuljka"},
"5074":{"field":"E", "location":"74", "inscription":"Anica Boban Slavko Boban"},
"5075":{"field":"E", "location":"75", "inscription":"Obitelj Mate Šimić - Pekin"},
"5076":{"field":"E", "location":"76", "inscription":"Vlašić"},
"5077":{"field":"E", "location":"77", "inscription":"Obitelj Pejić - Žarkić -"},
"5078":{"field":"E", "location":"78", "inscription":"Bez oznake"},
"5079":{"field":"E", "location":"79", "inscription":"Obiteljska grobnica Petra Kešića"},
"5080":{"field":"E", "location":"80", "inscription":"Kešić"},
"5081":{"field":"E", "location":"81", "inscription":"Obiteljska grobnica Pejić Alojzije"},
"5082":{"field":"E", "location":"82", "inscription":"Obitelj Pejić"},
"5083":{"field":"E", "location":"83", "inscription":"Obitelj Pejić - Bobanušić"},
"5084":{"field":"E", "location":"84", "inscription":"Bez oznake"},
"5085":{"field":"E", "location":"85", "inscription":"Neda Šimić"},
"5086":{"field":"E", "location":"86", "inscription":"Obitelj Soldo"},
"5087":{"field":"E", "location":"87", "inscription":"Bez oznake"},
"5088":{"field":"E", "location":"88", "inscription":"Obiteljska grobnica Jure Boban"},
"5089":{"field":"E", "location":"89", "inscription":"Obitelj Stjepana Bobana - Ćipića -"},
"5090":{"field":"E", "location":"90", "inscription":"Obiteljska grobnica Joze Vlašić"},
"5091":{"field":"E", "location":"91", "inscription":"Pejić"},
"5092":{"field":"E", "location":"92", "inscription":"Obitelj Zorić"},
"5093":{"field":"E", "location":"93", "inscription":"Obiteljska grobnica Petra Zorića"},
"5094":{"field":"E", "location":"94", "inscription":"Obiteljska grobnica Jelić"},
"5095":{"field":"E", "location":"95", "inscription":"Obiteljska grobnica Benović"},
"5096":{"field":"E", "location":"96", "inscription":"Obiteljska grobnica Stjepana Bobana"},
"5097":{"field":"E", "location":"97", "inscription":"Obiteljska grobnica Blago Grubišić"},
"5098":{"field":"E", "location":"98", "inscription":"Boban Grubišić"},
"5099":{"field":"E", "location":"99", "inscription":"Obitelj Jerke Bobana (Kebića)"},
"5100":{"field":"E", "location":"100", "inscription":"Obitelj Čuljak"},
"5101":{"field":"E", "location":"101", "inscription":"Obitelj Čuljak"},
"5102":{"field":"E", "location":"102", "inscription":"Obiteljska grobnica Dominik Boban"},
"5103":{"field":"E", "location":"103", "inscription":"Bez oznake"},
"5104":{"field":"E", "location":"104", "inscription":"Obiteljska grobnica Stjepana Pejića i žene Ive"},
"5105":{"field":"E", "location":"105", "inscription":"Obitelj Vlade Šimića"},
"5106":{"field":"E", "location":"106", "inscription":"Obitelj Stjepan Boban - Pipe"},
"5107":{"field":"E", "location":"107", "inscription":"Boban"},
"5108":{"field":"E", "location":"108", "inscription":"Obiteljska grobnica Mirko Šimić (Mirketa)"},
"5109":{"field":"E", "location":"109", "inscription":"Obiteljska grobnica Stjepana Zorića"},
"5110":{"field":"E", "location":"110", "inscription":"Obiteljska grobnica Zorić"},
"5111":{"field":"E", "location":"111", "inscription":"Obiteljska grobnica Šimuna Zorića"},
"5112":{"field":"E", "location":"112", "inscription":"Obitelj Jakova Zorić"},
"5113":{"field":"E", "location":"113", "inscription":"Obitelj Zorić"},
"5114":{"field":"E", "location":"114", "inscription":"Obiteljska grobnica Pejić Petra"},
"5115":{"field":"E", "location":"115", "inscription":"Obitelj Zdravke Pejića \"Knezovušić\""},
"5116":{"field":"E", "location":"116", "inscription":"Obiteljska grobnica Vulić Zdravke"},
"5117":{"field":"E", "location":"117", "inscription":"Bez oznake"},
"5118":{"field":"E", "location":"118", "inscription":"Obitelj Ivana Bobana (Barića)"},
"5119":{"field":"E", "location":"119", "inscription":"Obiteljska grobnica Mate Solde"},
"5120":{"field":"E", "location":"120", "inscription":"Soldo"},
"5121":{"field":"E", "location":"121", "inscription":"Obiteljska grobnica Ivana (Cavorikana) Solde"},
"5122":{"field":"E", "location":"122", "inscription":"Obitelj Mate Vulić"},
"5123":{"field":"E", "location":"123", "inscription":"Vulić"},
"5124":{"field":"E", "location":"124", "inscription":"Obitelj Stjepan Boban"},
"5125":{"field":"E", "location":"125", "inscription":"Obitelj Pejić"},
"5126":{"field":"E", "location":"126", "inscription":"Obiteljska grobnica Frano Pejić Pipanović"},
"5127":{"field":"E", "location":"127", "inscription":"Obitelj Stjepan Pejić-Pipanović"},
"5128":{"field":"E", "location":"128", "inscription":"Pejić"},
"5129":{"field":"E", "location":"129", "inscription":"Obitelj Mikulić"},
"5130":{"field":"E", "location":"130", "inscription":"Obiteljska grobnica Milićević Križan"},
"5131":{"field":"E", "location":"131", "inscription":"Obitelj Vence Milićevića"},
"5132":{"field":"E", "location":"132", "inscription":"Obiteljska grobnica Sulić Ivana"},
"5133":{"field":"E", "location":"133", "inscription":"Obitelj Jerke Sulića"},
"5134":{"field":"E", "location":"134", "inscription":"Soldo"},
"5135":{"field":"E", "location":"135", "inscription":"Obiteljska grobnica Landeka Ante"},
"5136":{"field":"E", "location":"136", "inscription":"Obiteljska grobnica Milićević"},
"5137":{"field":"E", "location":"137", "inscription":"Obiteljska grobnica Slišković"},
"5138":{"field":"E", "location":"138", "inscription":"Obitelj Ivan Slišković -Ćirilović-"},
"5139":{"field":"E", "location":"139", "inscription":"Soldo"},
"5140":{"field":"E", "location":"140", "inscription":"Obiteljska grobnica Stjepan Misir -Dudić-"},
"5141":{"field":"E", "location":"141", "inscription":"Čuljak"},
"5142":{"field":"E", "location":"142", "inscription":"Obitelj Prlić"},
"5143":{"field":"E", "location":"143", "inscription":"Obiteljska grobnica Prlić"},
"5144":{"field":"E", "location":"144", "inscription":"Rafo Vulić"},
"5145":{"field":"E", "location":"145", "inscription":"Obiteljska grobnica Ivana Pejića"},
"5146":{"field":"E", "location":"146", "inscription":"Obitelj Pejić"},
"5147":{"field":"E", "location":"147", "inscription":"Obiteljska grobnica Cvitana Pejić - Cvijo"},
"5148":{"field":"E", "location":"148", "inscription":"Obiteljska grobnica Ivan Šimić - Šabanović"},
"5149":{"field":"E", "location":"149", "inscription":"Obitelj Andrije mikulić"},
"5150":{"field":"E", "location":"150", "inscription":"Obitelj Ferde Mikulić"},
"5151":{"field":"E", "location":"151", "inscription":"Obitelj Pejić Jozetić"},
"5152":{"field":"E", "location":"152", "inscription":"Obiteljska grobnica Stjepana i Križana Pejić"},
"5153":{"field":"E", "location":"153", "inscription":"Obiteljska grobnica Bošnjak"},
"5154":{"field":"E", "location":"154", "inscription":"Obitelj Damjana - Dade Šimića"},
"5155":{"field":"E", "location":"155", "inscription":"Ivan Šimić (Puka)"},
"5156":{"field":"E", "location":"156", "inscription":"Obitelj Boban Grubišić"},
"5157":{"field":"E", "location":"157", "inscription":"Obiteljska grobnica Frano Boban -Kuko-"},
"5158":{"field":"E", "location":"158", "inscription":"Obitelj Stipana-Pikaća Bobana"},
"5159":{"field":"E", "location":"159", "inscription":"Boban (Puce)"},
"5160":{"field":"E", "location":"160", "inscription":"Obiteljska grobnica Nikice Sulića"},
"5161":{"field":"E", "location":"161", "inscription":"Stjepan Vidaćak"},
"5162":{"field":"E", "location":"162", "inscription":"Obitelj Prlić"},
"5163":{"field":"E", "location":"163", "inscription":"Obiteljska grobnica Šimić"},
"5164":{"field":"E", "location":"164", "inscription":"Bez oznake"},
"5165":{"field":"E", "location":"165", "inscription":"Obitelj Mikulić"},
"5166":{"field":"E", "location":"166", "inscription":"Obitelj Sulić"},
"5167":{"field":"E", "location":"167", "inscription":"Obiteljska grobnica Nikole Mikulić"},
"5168":{"field":"E", "location":"168", "inscription":"Boro Mikulić"},
"5169":{"field":"E", "location":"169", "inscription":"Bez oznake"},
"5170":{"field":"E", "location":"170", "inscription":"Obitelj Vice Bošnjaka"},
"5171":{"field":"E", "location":"171", "inscription":"Bez oznake"},
"5172":{"field":"E", "location":"172", "inscription":"Obitelj Dane Šimić"},
"5173":{"field":"E", "location":"173", "inscription":"Zorka Landeka"},
"5174":{"field":"E", "location":"174", "inscription":"Bez oznake"},
"5175":{"field":"E", "location":"175", "inscription":"Bez oznake"},
"5176":{"field":"E", "location":"176", "inscription":"Ana i Ikas Boban"}
}

export default mapData;