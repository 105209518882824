import React, { useRef } from "react";
import SVGMap from "./SVGMap"
import mapData from "./MapData"

import "./Map.css";

function Map() {
  const tooltipRef = useRef(null);
  const tooltipHeaderRef = useRef(null);
  const tooltipContentRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const imageDescHeaderRef = useRef(null);
  const imageDescContentRef = useRef(null);
  const infoRef = useRef(null);
  const infoButtonRef = useRef(null);

  // pinch-zoom and pan
  let startDragPoint;
  let imageStartDragTopLeft;
  let cachedPoints = [];
  let pinchZoomAction = false;
  let startPinchZoomPoints = [];
  let initialImageViewBox;

  let timeoutId;

  let currentObjectId;

  function onObjectClicked(e) {
    currentObjectId = e.target.id;
    imageRef.current.src = "photo/" + currentObjectId + ".jpg";
    imageDescHeaderRef.current.innerText = mapData[currentObjectId].field + " " + mapData[currentObjectId].location;
    imageDescContentRef.current.innerText = mapData[currentObjectId].inscription;
  }

  function onObjectMouseEnter(e) {
    // Dont react on mouse events if user on mobile device
    if(!window.matchMedia("(max-width: 980px)").matches) {
      clearTimeout(timeoutId);
      tooltipHeaderRef.current.innerText = mapData[e.target.id].field + " " + mapData[e.target.id].location;
      tooltipContentRef.current.innerText = mapData[e.target.id].inscription;
      tooltipRef.current.style.display = "grid";
      timeoutId = setTimeout(() => {
        tooltipRef.current.style.display = "grid";
      }, 100);
    }
  }

  function onObjectMouseMove(e) {
    // Dont react on mouse events if user on mobile device
    if(!window.matchMedia("(max-width: 980px)").matches) {
      let left = e.clientX + 20;
      let top = e.clientY - tooltipRef.current.clientHeight / 2;
      tooltipRef.current.style.left = left.toString() + "px";
      tooltipRef.current.style.top = top.toString() + "px";
    }
  }

  function onObjectMouseLeave(e) {
    // Dont react on mouse events if user on mobile device
    if(!window.matchMedia("(max-width: 980px)").matches) {
      clearTimeout(timeoutId);
      tooltipRef.current.style.display = "none";
    }
  }

  function onImageError(e) {
    console.log("onImageError");
    imageRef.current.src = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D";
  }

  function onImageLoaded(e) {
    imageContainerRef.current.style.display = "block";
    
    clearTimeout(timeoutId);
    tooltipRef.current.style.display = "none";

    let containerRatio = imageContainerRef.current.clientWidth / imageContainerRef.current.clientHeight;
    let imageRatio = e.target.naturalWidth / e.target.naturalHeight;
    let newImageHeight = 0;
    let newImageWidth = 0;
    if (containerRatio >= imageRatio) {
      newImageHeight = imageContainerRef.current.clientHeight;
      newImageWidth = newImageHeight * imageRatio;
    }
    else {
      newImageWidth = imageContainerRef.current.clientWidth;
      newImageHeight = newImageWidth / imageRatio;
    }

    let newImageTop = (imageContainerRef.current.clientHeight - newImageHeight) / 2;
    let newImageLeft = (imageContainerRef.current.clientWidth - newImageWidth) / 2;

    imageRef.current.style.width = newImageWidth.toString() + "px";
    imageRef.current.style.height = newImageHeight.toString() + "px";
    imageRef.current.style.top = newImageTop.toString() + "px";
    imageRef.current.style.left = newImageLeft.toString() + "px";

    if (infoRef.current.style.display != "none") {
      onInfoClicked(null);
    }

    initialImageViewBox = {
      x: newImageLeft,
      y: newImageTop,
      width: newImageWidth,
      height: newImageHeight,
    }
  }

  function onInfoClicked(e) {
    infoRef.current.style.display = "none";
    infoButtonRef.current.style.display = "flex";
  }

  function onInfoButtonClicked(e) {
    infoRef.current.style.display = "flex";
    infoButtonRef.current.style.display = "none";
  }

  function onImagePointerDown(e) {
    if (e.pointerType != "touch") {
      return;
    }

    cachedPoints.push({id: e.pointerId, x: e.clientX, y: e.clientY});

    if (cachedPoints.length == 1) {
      startDragPoint = {x: e.clientX, y: e.clientY}
      imageStartDragTopLeft = {
        x: parseInt(imageRef.current.style.left.substring(0, imageRef.current.style.left.length - 2)),
        y: parseInt(imageRef.current.style.top.substring(0, imageRef.current.style.top.length - 2)),
      }
    }

    if (cachedPoints.length == 2) {
      startPinchZoomPoints = [{x: cachedPoints[0].x, y: cachedPoints[0].y}, {x: cachedPoints[1].x, y: cachedPoints[1].y}];

      pinchZoomAction = true;
    }
  }

  function onImagePointerMove(e) {
    if (e.pointerType != "touch") {
      return;
    }

    // Find this event in the cache and update its record with this event
    for (let i = 0; i < cachedPoints.length; i++) {
      if (e.pointerId == cachedPoints[i].id) {
        cachedPoints[i].x = e.clientX;
        cachedPoints[i].y = e.clientY;
      break;
      }
    }

    // Click/touch and drag
    if (!pinchZoomAction && cachedPoints.length == 1) {
      //current drag point on svg canvas
      let currentDragPoint = {x: e.clientX, y: e.clientY}

      //add diff to viewBox x and y
      let diff = {x: currentDragPoint.x - startDragPoint.x, y: currentDragPoint.y - startDragPoint.y}
      let newLeft = imageStartDragTopLeft.x + diff.x;
      let newTop = imageStartDragTopLeft.y + diff.y;
      imageRef.current.style.left = newLeft.toString() + "px";
      imageRef.current.style.top = newTop.toString() + "px";
    }

    // Pinch-zoom
    if (cachedPoints.length == 2) {
      // Calculate the distance between the two pointers
      let startDiff = Math.sqrt(Math.pow(Math.abs(startPinchZoomPoints[0].x - startPinchZoomPoints[1].x), 2) + Math.pow(Math.abs(startPinchZoomPoints[0].y - startPinchZoomPoints[1].y), 2));
      let curDiff = Math.sqrt(Math.pow(Math.abs(cachedPoints[0].x - cachedPoints[1].x), 2) + Math.pow(Math.abs(cachedPoints[0].y - cachedPoints[1].y), 2));
      let scale = curDiff / startDiff;

      if (scale > 1) {
        zoom(1.015);
      }
      else {
        zoom(0.985);
      }
    }
  }

  function onImagePointerUp(e) {
    if (e.pointerType != "touch") {
      return;
    }

    for (var i = 0; i < cachedPoints.length; i++) {
      if (cachedPoints[i].id == e.pointerId) {
        cachedPoints.splice(i, 1);
        break;
      }
    }

    if (cachedPoints.length == 0) {
      pinchZoomAction = false;
    }
  }

  function zoom(scale) {
    let oldViewBox = {
      x: parseInt(imageRef.current.style.left.substring(0, imageRef.current.style.left.length - 2)),
      y: parseInt(imageRef.current.style.top.substring(0, imageRef.current.style.top.length - 2)),
      width: parseInt(imageRef.current.style.width.substring(0, imageRef.current.style.width.length - 2)),
      height: parseInt(imageRef.current.style.height.substring(0, imageRef.current.style.height.length - 2)),
    }

    let newViewBox = {
      x: oldViewBox.x - (oldViewBox.width * (scale - 1)) / 2,
      y: oldViewBox.y - (oldViewBox.height * (scale - 1)) / 2,
      width: oldViewBox.width * scale,
      height: oldViewBox.height * scale,
    }
    if (newViewBox.width < initialImageViewBox.width) {
      imageRef.current.style.width = initialImageViewBox.width.toString() + "px";
      imageRef.current.style.height = initialImageViewBox.height.toString() + "px";
    }
    else if (newViewBox.width > 4 * initialImageViewBox.width) {
      imageRef.current.style.width = 4 * initialImageViewBox.width.toString() + "px";
      imageRef.current.style.height = 4 * initialImageViewBox.height.toString() + "px";
    }
    else {
      imageRef.current.style.left = newViewBox.x.toString() + "px";
      imageRef.current.style.top = newViewBox.y.toString() + "px";
      imageRef.current.style.width = newViewBox.width.toString() + "px";
      imageRef.current.style.height = newViewBox.height.toString() + "px";
    }
  }

  function onImageClose() {
    imageContainerRef.current.style.display = "none";
    cachedPoints = [];
    pinchZoomAction = false;
  }


  function onImageLeft() {
    let newObjectId = parseInt(currentObjectId) - 1;
    let newObjectIdStr = newObjectId.toString();
    if (mapData[newObjectIdStr] != undefined) {
      onObjectClicked({target: {id: newObjectIdStr}})
    }
  }

  function onImageRight() {
    let newObjectId = parseInt(currentObjectId) + 1;
    let newObjectIdStr = newObjectId.toString();
    if (mapData[newObjectIdStr] != undefined) {
      onObjectClicked({target: {id: newObjectIdStr}})
    }
  }

  return (
    <>
      <div ref = {imageContainerRef} className = "object-image" >
        <img ref = {imageRef} className = "object-image__img" onError = {onImageError} onLoad = {onImageLoaded} onPointerDown = {onImagePointerDown} onPointerUp = {onImagePointerUp} onPointerMove = {onImagePointerMove}/>
        <div className = "object-image__desc">
          <div ref = {imageDescHeaderRef} className = "object-image__desc-header"/>
          <div ref = {imageDescContentRef} className = "object-image__desc-content"/>
        </div>
        <div className = "object-image__close" onClick = {onImageClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path className = "object-image__svg-close-icon" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 15.538l-3.592-3.548 3.546-3.587-1.416-1.403-3.545 3.589-3.588-3.543-1.405 1.405 3.593 3.552-3.547 3.592 1.405 1.405 3.555-3.596 3.591 3.55 1.403-1.416z"/></svg>
        </div>
        <div className = "object-image__left" onClick = {onImageLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 48"><path className = "object-image__svg-arrow-icon" d="M 22,2 2,24 22,46"/></svg>
        </div>
        <div className = "object-image__right" onClick = {onImageRight}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 48"><path className = "object-image__svg-arrow-icon" d="M 2,2 22,24 2,46"/></svg>
        </div>

      </div>

      <div ref = {tooltipRef} className = "object-tooltip">
        <div ref = {tooltipHeaderRef} className = "object-tooltip__header"/>
        <div ref = {tooltipContentRef} className = "object-tooltip__content"/>
      </div>
      <div ref = {infoRef} className = "info" onClick = {onInfoClicked}>
        <span className = "info__pc">
          &#8227; Pozicionirajte pokazivač miša iznad objekta za detalje<br/>
          &#8227; Kliknite na objekt za sliku<br/>
          &#8227; Koristite kotačić miša za povećanje/smanjivanje karte<br/>
          &#8227; Na povećanoj karti kliknite i vučite za promjenu pozicije<br/>
        </span>
        <span className = "info__mobile">
          &#8227; Pritisnite na objekt za sliku<br/>
          &#8227; Koristite dva prsta za povećanje/smanjivanje karte<br/>
          &#8227; Na povećanoj karti pritisnite i vučite za promjenu pozicije<br/>
        </span>
      </div>
      <div ref = {infoButtonRef} className = "info__button" onClick = {onInfoButtonClicked}>i</div>
      <SVGMap onObjectClicked = {onObjectClicked} onObjectMouseEnter = {onObjectMouseEnter} onObjectMouseMove = {onObjectMouseMove} onObjectMouseLeave = {onObjectMouseLeave}/>
    </>
  );
}

export default Map;