import React, { useState } from "react";
import StartPage from "./components/StartPage";
import Map from "./components/Map";
import ContactPage from "./components/ContactPage";

import "./App.css"

function App() {
  const [selectedTab, setSelectedTab] = useState(0);

  function tabButtonClicked(e) {
    switch (e.target.id) {
      case "start-page-button":
        setSelectedTab(0);
        break;
      case "map-button":
        setSelectedTab(1);
        break;
      case "contact-button":
        setSelectedTab(2);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className = "tab-buttons">
        <button id = "start-page-button" className = {selectedTab === 0 ? "tab-button active" : "tab-button"} onClick = {tabButtonClicked}>NASLOVNICA</button>
        <button id = "map-button" className = {selectedTab === 1 ? "tab-button active" : "tab-button"} onClick = {tabButtonClicked}>KARTA</button>
        <button id = "contact-button" className = {selectedTab === 2 ? "tab-button active" : "tab-button"} onClick = {tabButtonClicked}>KONTAKT</button>
      </div>
      <div className = "tab-content" hidden = {selectedTab === 1 || selectedTab === 2}>
        <StartPage/>
      </div>
      <div className = "tab-content" style = {{touchAction: "none"}} hidden = {selectedTab === 0 || selectedTab === 2}>
        <Map/>
      </div>
      <div className = "tab-content" style = {{touchAction: "none"}} hidden = {selectedTab === 0 || selectedTab === 1}>
        <ContactPage/>
      </div>
    </>
  );
}

export default App;