import React from "react";

import "./StartPage.css";

function StartPage() {
  return (
    <>
      <div className="start-page__title">Obavijest - 03.04.2023</div>
      <div>

      </div>
      <div className="start-page__content">
        <div className="start-page__note">
          <div>
            Hrvatsko katoličko pogrebno društvo Gorica-Sovići je u 2021. godini pokrenulo projekt
            ograđivanja groblja. U tu svrhu Župni ured Gorica-Sovići i tadašnji župnik fra Stipe Marković
            dodijelio je zemljište na 99 godina označeno kao 907/3, ukupne površine 3200 m<sup>2</sup>.<br></br><br></br>
            Urađen je projekt ograđivanja groblja, projekt novih potpornih zidova te proširenja južne
            prometnice.
            Krajem 2022. godine, društvo je predalo svu potrebnu dokumentaciju za izdavanje potrebnih
            dozvola za navedene radove.
            U prvoj fazi radova trebalo bi uraditi iskope za nove grobnice (48 novih ukupnih mjesta),
            postaviti podložni beton te ugraditi grobnice.
            Po završetku tamponiranja platoa oko grobnica pristupiti izradi zidova oko parkirališta, iznad
            groblja i zidova oko prometnice sa istočne strane groblja.
            Kolnik ojačati cestovnim ivičnjacima, a sam ivičnjak uraditi flasterom, potporne zidove obložiti kamenom oblogom.
            U zidu sa južne i sa istočne strane groblja ostaviti kapije kako je naznačeno u projektu.
            Oblogu stupova kapija uraditi na identičan način kao što je urađena obloga na već
            postojećoj kapiji u zidu sa zapadne strane groblja. Kapije, također, uraditi od istovjetnih
            materijala i na isti način i izgled kao što je urađena i kapija u zapadnom zidu.<br></br><br></br>
            Svi zainteresirani se pozivaju na javni uvid ovog projekta pismenim putem, punim imenom i prezimenom
            na mail <a href="mailto:hkpdgoricasovici@gmail.com">hkpdgoricasovici@gmail.com</a> uputiti svoje mišljenje, prijedloge i sugestije.
            Nepotpisani dopisi neće biti uzeti u razmatranje.
            Javni uvid će biti otvoren mjesec dana od dana objavljivanja ove obavijesti.
            Plan će biti također izložen i na službenim prostorijama Društva kao i na oglasnim pločama
            naših crkvi u Gorici i Sovićima.
            U ljetnom vremenu se nadamo završetku svih procedura oko projekta da možemo krenuti u
            realizaciju.
            Hvala vam unaprijed !
          </div>
        </div>
      </div>
      <br></br><br></br>
      <a className="start-page__content" href = "photo_obavijesti/02042023.png">
        <img src = "photo_obavijesti/02042023.png" alt = "Slika" style={{width: "30%", height: "30%"}}></img>
      </a>
      <div className="start-page__seperator"></div>
      <div className="start-page__title">Dobrodošli na stranice projekta digitalizacije groblja Gorica - Sovići</div>
      <div className="start-page__content">
        <span className="start-page__line">U ovoj početnoj fazi projekta cilj je doći do točnih podataka o tome tko ima prava ali i obveze na određenim ukopnim mjestima.</span>
        <span className="start-page__line">U svrhu tog cilja na ovoj stranici smo podigli interaktivnu kartu groblja da biste lakše identificirali ukopno mjesto na koje polažete prava.</span>
        <span className="start-page__line">Za svako ukopno mjesto su dostupne kratke informacije sa nadgrobnog spomenika kao i slika samog ukopnog mjesta.</span>
        <span className="start-page__line">Molimo vas da pregledate kartu, pronađete ukopno mjesto na koje polažete prava, a o naknadnom upisu ćete biti obavješteni.</span>
        <span className="start-page__line">Ukoliko uočite bilo kakve pogreške, imate pitanja ili prijedloge javite se na <a href="mailto:hkpdgoricasovici@gmail.com">hkpdgoricasovici@gmail.com</a> ili <a href="tel:+387 63 999 580">+387 63 999 580</a>.</span>
      </div>
      <div className="start-page__seperator"></div>
    </>
  );
}

export default StartPage;